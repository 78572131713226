body {
  margin: 0;
    font-family: Roboto,'Franklin Gothic Medium',Tahoma,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.btn {
  width: auto;
  padding: 14px 24px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.btn-space {
  margin-right: 40px;
}
.navbar-brand>img {
  max-height: 100%;
  height: 100%;
  width: auto;
  margin: 0 auto;


  -o-object-fit: contain;
  object-fit: contain; 

} 
.modal-dialog {
  max-width: 800px;
  margin: 30px auto;
}



.modal-body {
  position:relative;
  padding:0px;
}
.close {
  position:absolute;
  right:-30px;
  top:0;
  z-index:999;
  font-size:2rem;
  font-weight: normal;
  color:#fff;
  opacity:1;
}
.footer-copyright {
  background-color: rgba(0,0,0,.2);
}

.footer-n-link {
    display: block;
    padding-top: 7px;
    padding-bottom: 7px;
    color: #c2c2c2;
    line-height: 18px;
    font-weight: 300;
}

.footer-n-link, .footer-text {
    /**font-size: 14px;*/
    text-align: left;
}

.footer-text {
    margin-bottom: 20px;
    color: #fff;
}
.footer-n-link, .footer-text {
    color: #fff;
    display: block;
    line-height: 1.6;
    padding: 8px 8px 8px 0;
    
}
.footer-text, .pp-link {
    text-transform: uppercase;
}

.service-image-horizontal {
    width: 64;
}
.icon-flex-container {
    margin-right: 20px;
    margin-left: 20px;
}

.flex-container {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
}
.h1, .h2, .h3, .h4, h1, h2, h3, h4, h5 {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
    display: block;
    font-family: Roboto,'Franklin Gothic Medium',Tahoma,sans-serif;
    font-weight: 500;
}
footer .column__links
a {
    color: #fff;
    display: block;
    line-height: 1.6;
    padding: 8px 8px 8px 0;
}

.geosuggest {
  font-size: 18px;
  font-size: 1rem;
  position: relative;
  width: 100%;
  margin: 1em auto;
  text-align: left;
}
.geosuggest__input {
  width: 100%;
  border: 2px solid transparent;
  box-shadow: 0 0 1px #3d464d;
  padding: .5em 1em;
  -webkit-transition: border 0.2s, box-shadow 0.2s;
          transition: border 0.2s, box-shadow 0.2s;
}
.geosuggest__input:focus {
  border-color: #267dc0;
  box-shadow: 0 0 0 transparent;
}
.geosuggest__suggests {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 25em;
  padding: 0;
  margin-top: -1px;
  background: #fff;
  border: 2px solid #267dc0;
  border-top-width: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  z-index: 5;
  -webkit-transition: max-height 0.2s, border 0.2s;
          transition: max-height 0.2s, border 0.2s;
}
.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}

.geosuggest__item {
  font-size: 18px;
  font-size: 1rem;
  padding: .5em .65em;
  cursor: pointer;
}
.geosuggest__item:hover,
.geosuggest__item:focus {
  background: #f5f5f5;
}
.geosuggest__item--active {
  background: #267dc0;
  color: #fff;
}
.geosuggest__item--active:hover,
.geosuggest__item--active:focus {
  background: #ccc;
}
.geosuggest__item__matched-text {
  font-weight: bold;
}

.on-top {
  z-index:0;
}
.input-group > .form-control {
  z-index: 0;
}

::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(200,200,200,1);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:green;
    -webkit-box-shadow: inset 0 0 6px rgba(90,90,90,0.7);
}
