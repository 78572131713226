.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus,
.close:active,
.close:focus,
.header-search form .btn.active:focus,
.header-search form .btn:active:focus,
.header-search form .btn:focus,
a:focus {
  outline: 0
}

.header-search,
.jPanelMenu-panel {
  overflow: hidden
}

.card.clickable-element,
.clickable-element,
.nav-link,
.overlay .close,
.overlay-trigger {
  cursor: pointer
}

.blockquote-bubble,
.flex-valign,
.flex-valign-b,
.header-inner,
.header-inner .header-block-flex,
.progress-bar,
.theme-colours {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex
}

.header-inner,
.header-inner .header-block-flex,
.progress-bar {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center
}


.header-inner,
.header-inner .header-block-flex {
  -webkit-box-pack: end;
  -webkit-justify-content: end;
  -ms-flex-pack: end;
  justify-content: end
}

.header-inner,
.header-inner .header-block-flex,
.theme-colours {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}


.btn-link,
.btn-link:hover {
  color: #55A79A
}

.text-primary,
a.text-primary:focus,
a.text-primary:hover {
  color: #55A79A!important
}

.btn-outline-primary,
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #55A79A;
  border-color: #55A79A
}

.btn-outline-primary.disabled:focus,
.btn-outline-primary.disabled:hover,
.btn-outline-primary:disabled:focus,
.btn-outline-primary:disabled:hover,
.btn-outline-primary:focus,
.btn-outline-primary:hover {
  color: #fff
}

.btn-outline-primary.active,
.btn-outline-primary.active:focus,
.btn-outline-primary.focus,
.btn-outline-primary:active,
.btn-outline-primary:active:focus,
.btn-outline-primary:focus,
.btn-outline-primary:hover,
.open>.btn-outline-primary.dropdown-toggle,
.show>.btn-outline-primary.dropdown-toggle {
  background-color: #55A79A;
  border-color: #55A79A
}

.header .header-brand-text,
.price-banner {
  color: #55A79A
}


.modal-progress,
.overlay-hover-content.overlay-primary:after,
.overlay.overlay-primary:after,
.owl-dots .owl-dot.active span,
.owl-dots .owl-dot:hover span,
.owl-nav div:hover,
.page-loader {
  background: #55A79A!important
}

body,
html {
  font-family: "Open Sans", Arial, serif;
  background: #fff
}

body {
  position: relative
}

a,
a:focus,
a:hover {
  color: #55A79A
}

a:hover img {
  opacity: 80
}

p {
  margin-bottom: 15px
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-weight: 700;
  line-height: 1.1
}


.h1,
h1 {
  font-size: 2.45rem
}

.h2,
h2 {
  font-size: 2.15rem
}

.h3,
h3 {
  font-size: 1.75rem
}

.h4,
h4 {
  font-size: 1.25rem
}

.h5,
h5 {
  font-size: 1rem
}

.header .header-brand-text,
.header .header-brand-text h1 {
  font-family: Rambla, Arial, serif
}

#header {
  z-index: 1000;
  position: relative;
  color: #eee;
  color: rgba(255, 255, 255, .7)
}

#header::after {
  display: block;
  content: "";
  clear: both
}

.header-inner .header-brand {
  margin-right: auto
}

@media (min-width:768px) {
  .header-inner .header-block,
  .header-inner .header-brand {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -webkit-justify-content: end;
    -ms-flex-pack: end;
    justify-content: end;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
  }
}


.header-search {
  background: #404040
}

.header {
  background: #fff;
  border-bottom: 1px solid rgba(85, 167, 154, .6);
  color: #1b1b1b;
  height: auto;
  padding: 25px 0;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  width: 100%;
  right: 0
}

.header .navbar {
  position: static
}

.header .header-brand-text {
  font-weight: 700;
  padding: 0;
  margin: 0;
  display: block;
  border: none;
  text-shadow: none;
  line-height: 1;
  max-width: none;
  text-align: left;
  float: none;
  height: auto;
  text-decoration: none
}

.header .header-btn {
  margin-left: 3px
}

.header.is-sticky,
.is-sticky .header {
  right: 0;
  width: 100%!important
}

#header .sticky {
  -webkit-transition: all .15s ease-in-out;
  -moz-transition: all .15s ease-in-out;
  -ms-transition: all .15s ease-in-out;
  -o-transition: all .15s ease-in-out;
  transition: all .15s ease-in-out
}


.navbar {
  color: #eee;
  color: rgba(255, 255, 255, .7);
  margin-bottom: 0;
  background: 0 0;
  border-radius: 0;
  padding: 0
}

.navbar::after {
  display: block;
  content: "";
  clear: both
}

.navbar-main .navbar-nav {
  float: none;
  margin: 0;
  padding: 0;
  border-radius: 2px
}

.navbar-main .navbar-nav .nav-item+.nav-item {
  margin: 0
}

.navbar-main .navbar-nav>.nav-item {
  float: none;
  border-left: none;
  border-right: none;
  padding: 0;
  margin: 0;
  border-top: 1px solid rgba(255, 255, 255, .1);
  border-bottom: 1px solid #242424
}

.navbar-main .navbar-nav>.nav-item>.nav-link,
.navbar-main .navbar-nav>.nav-link {
  padding: 15px 20px;
  margin: 0;
  font-weight: 400;
  font-size: 13px;
  text-transform: uppercase;
  /*color: rgba(255, 255, 255, .7);*/
  background-color:white;
}

.navbar-main .navbar-nav>.nav-item>.nav-link .nav-link-icon,
.navbar-main .navbar-nav>.nav-link .nav-link-icon {
  font-size: 18px
}

.navbar-main {
  margin: 0;
  padding: 0;
  background-color: #292b2c;
  position: relative
}

@media (min-width:992px) {
  .navbar-toggleable-md .navbar-main,
  .navbar-toggleable-md.navbar-main {
    display: block!important;
    padding: 0;
    margin: 0;
    clear: none;
    overflow-y: visible;
    overflow-x: visible;
    width: auto;
    top: auto;
    background: 0 0;
    position: static
  }
  .navbar-toggleable-md .navbar-main .navbar-nav,
  .navbar-toggleable-md.navbar-main .navbar-nav {
    margin-top: 0
  }
  .navbar-toggleable-md .navbar-main .nav-item,
  .navbar-toggleable-md.navbar-main .nav-item {
    float: left;
    border: none
  }
  .navbar-toggleable-md .navbar-main .navbar-nav>.nav-item>.nav-link,
  .navbar-toggleable-md .navbar-main .navbar-nav>.nav-link,
  .navbar-toggleable-md.navbar-main .navbar-nav>.nav-item>.nav-link,
  .navbar-toggleable-md.navbar-main .navbar-nav>.nav-link {
    border-radius: 0;
    -webkit-transition: all .3s ease-out;
    -moz-transition: all .3s ease-out;
    -ms-transition: all .3s ease-out;
    -o-transition: all .3s ease-out;
    transition: all .3s ease-out;
    padding: 18px 14px;
    margin: 0;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    color: #888;
    border: 1px solid transparent;
    border-bottom: none;
    border-top: 3px solid transparent
  }
  .navbar-toggleable-md .navbar-main .navbar-nav>.nav-item>.nav-link .nav-link-icon,
  .navbar-toggleable-md .navbar-main .navbar-nav>.nav-link .nav-link-icon,
  .navbar-toggleable-md.navbar-main .navbar-nav>.nav-item>.nav-link .nav-link-icon,
  .navbar-toggleable-md.navbar-main .navbar-nav>.nav-link .nav-link-icon {
    font-size: 20px;
    margin-top: -5px
  }
.card,
.has-page-loader,
.overlay-open {
  overflow: hidden
}

/**
.btn,
.collapse .dropdown-menu .dropdown-item strong {
  font-weight: 400
}


.dropdown-menu {
  margin-top: -1px!important;
  padding: 4px;
  border-radius: 0;
  left: auto;
  border: 1px solid #E6E6E6;
  border-bottom-width: 2px;
  font-size: 13px
}

.dropdown-toggle::after {
  font-size: 12px
}

.dropdowns-no-carets .dropdown-toggle::after {
  display: none
}

.dropdown-icon {
  width: 1.4em
}

.open>.dropdown-menu,
.show>.dropdown-menu {
  display: block!important
}

.collapse .dropdown-menu {
  background: 0 0;
  margin: 0;
  padding: 0;
  max-width: 100%;
  border: none;
  color: rgba(255, 255, 255, .6);
  position: static;
  float: none!important;
  left: 0
}

.collapse .dropdown-menu:after,
.collapse .dropdown-menu:before {
  display: none
}

.collapse .dropdown-menu .divider {
  height: 1px;
  margin: 1em
}

.collapse .dropdown-menu .dropdown-item {
  padding: 8px 20px!important;
  margin: 0;
  background: #ffffff;
  text-transform: none;
  /**color: rgba(255, 255, 255, .6);*/
  font-size: 12px;
  display: block;
  float: none;
  line-height: 20px;
  border-bottom: 1px solid #4e4e4e
}

*//

.code-block-link,
.stat small,
.timeline-item-date {
  text-transform: uppercase
}

.collapse .dropdown-menu .dropdown-item:before {
  font-family: FontAwesome;
  font-size: 12px;
  color: rgba(255, 255, 255, .6);
  width: 30px;
  height: 30px;
  content: "";
  padding: 0 10px 0 0
}

.collapse .dropdown-menu .dropdown-item.active,
.collapse .dropdown-menu .dropdown-item:hover {
  background: #2f2f2f;
  border-bottom-color: rgba(255, 255, 255, .16);
  color: #fff;
  text-decoration: none
}

.collapse .dropdown-menu .dropdown-item img {
  display: block;
  width: auto;
  height: auto;
  max-width: 100%
}

.collapse .dropdown-menu .dropdown-item span {
  font-size: 11px;
  display: none
}

.collapse .dropdown-menu .active>.dropdown-item,
.collapse .dropdown-menu .active>.dropdown-item:focus,
.collapse .dropdown-menu>.active>.dropdown-item,
.collapse .dropdown-menu>.active>.dropdown-item:focus,
.collapse .dropdown-menu>.open>.dropdown-item,
.collapse .dropdown-menu>.open>.dropdown-item:focus,
.collapse .dropdown-menu>.show>.dropdown-item,
.collapse .dropdown-menu>.show>.dropdown-item:focus {
  background: #2f2f2f;
  color: #fff
}

.collapse .dropdown-menu .active>.dropdown-item:focus:hover,
.collapse .dropdown-menu .active>.dropdown-item:hover,
.collapse .dropdown-menu>.active>.dropdown-item:focus:hover,
.collapse .dropdown-menu>.active>.dropdown-item:hover,
.collapse .dropdown-menu>.open>.dropdown-item:focus:hover,
.collapse .dropdown-menu>.open>.dropdown-item:hover,
.collapse .dropdown-menu>.show>.dropdown-item:focus:hover,
.collapse .dropdown-menu>.show>.dropdown-item:hover {
  background: #2a2a2a;
  color: #fff
}

.collapse .dropdown-menu .active>.dropdown-item:before,
.collapse .dropdown-menu .active>.dropdown-item:focus:before,
.collapse .dropdown-menu>.active>.dropdown-item:before,
.collapse .dropdown-menu>.active>.dropdown-item:focus:before,
.collapse .dropdown-menu>.open>.dropdown-item:before,
.collapse .dropdown-menu>.open>.dropdown-item:focus:before,
.collapse .dropdown-menu>.show>.dropdown-item:before,
.collapse .dropdown-menu>.show>.dropdown-item:focus:before {
  color: #fff;
  content: "\f107";
  margin-left: -2px
}

.collapse .dropdown-menu-nobullets .dropdown-item:before {
  display: none!important
}

.collapse .dropdown-menu .dropdown>.dropdown-item:after {
  border-style: solid;
  border-width: 0 0 10px 10px;
  border-color: transparent transparent #4e4e4e;
  display: block;
  bottom: 0;
  right: 0;
  position: absolute;
  -webkit-transition: all .2s ease;
  -moz-transition: all .2s ease;
  -ms-transition: all .2s ease;
  -o-transition: all .2s ease;
  transition: all .2s ease;
  line-height: 1;
  content: ''
}

.collapse .dropdown-menu .dropdown>.dropdown-item:hover:after {
  border-width: 0 0 14px 14px
}

.collapse .dropdown-menu .dropdown.open,
.collapse .dropdown-menu .dropdown.show {
  border-bottom: 1px solid #4e4e4e
}

.collapse .dropdown-menu .dropdown .dropdown-menu .dropdown-item {
  border-bottom: none;
  padding: 6px 30px!important
}

.collapse .dropdown-menu.mega-menu,
.mega-menu {
  padding: 15px;
  min-width: 100%;
  max-width: 100%
}

.dropdown-menu-mini {
  min-width: 10px!important
}

.dropdown-menu-mini>li>a {
  padding: 5px 10px
}

.dropdown-menu-default {
  border-bottom-color: #ccc!important
}

.dropdown-menu-default a:hover:before {
  color: #ccc!important
}

.dropdown-menu-primary {
  border-bottom-color: #55A79A!important
}

.dropdown-menu-primary a:hover:before {
  color: #55A79A!important
}

.dropdown-menu-info {
  border-bottom-color: #5bc0de!important
}

.dropdown-menu-info a:hover:before {
  color: #5bc0de!important
}

.dropdown-menu-success {
  border-bottom-color: #5cb85c!important
}

.dropdown-menu-success a:hover:before {
  color: #5cb85c!important
}

.dropdown-menu-warning {
  border-bottom-color: #f0ad4e!important
}

.dropdown-menu-warning a:hover:before {
  color: #f0ad4e!important
}

.dropdown-menu-danger {
  border-bottom-color: #d9534f!important
}

.dropdown-menu-danger a:hover:before {
  color: #d9534f!important
}

.dropdown-menu-green {
  border-bottom-color: #55A79A!important
}

.dropdown-menu-green a:hover:before {
  color: #55A79A!important
}

.dropdown-menu-red {
  border-bottom-color: #BE3E1D!important
}

.dropdown-menu-red a:hover:before {
  color: #BE3E1D!important
}

.dropdown-menu-blue {
  border-bottom-color: #00ADBB!important
}

.dropdown-menu-blue a:hover:before {
  color: #00ADBB!important
}

.dropdown-menu-purple {
  border-bottom-color: #b771b0!important
}

.dropdown-menu-purple a:hover:before {
  color: #b771b0!important
}

.dropdown-menu-pink {
  border-bottom-color: #CC164D!important
}

.dropdown-menu-pink a:hover:before {
  color: #CC164D!important
}

.dropdown-menu-orange {
  border-bottom-color: #e67e22!important
}

.dropdown-menu-orange a:hover:before {
  color: #e67e22!important
}

.dropdown-menu-lime {
  border-bottom-color: #b1dc44!important
}

.dropdown-menu-lime a:hover:before {
  color: #b1dc44!important
}

.dropdown-menu-blue-dark {
  border-bottom-color: #34495e!important
}

.dropdown-menu-blue-dark a:hover:before {
  color: #34495e!important
}

.dropdown-menu-red-dark {
  border-bottom-color: #a10f2b!important
}

.dropdown-menu-red-dark a:hover:before {
  color: #a10f2b!important
}

.dropdown-menu-brown {
  border-bottom-color: #91633c!important
}

.dropdown-menu-brown a:hover:before {
  color: #91633c!important
}

.dropdown-menu-cyan-dark {
  border-bottom-color: #008b8b!important
}

.dropdown-menu-cyan-dark a:hover:before {
  color: #008b8b!important
}

.dropdown-menu-yellow {
  border-bottom-color: #D4AC0D!important
}

.dropdown-menu-yellow a:hover:before {
  color: #D4AC0D!important
}

.dropdown-menu-slate {
  border-bottom-color: #5D6D7E!important
}

.dropdown-menu-slate a:hover:before {
  color: #5D6D7E!important
}

.dropdown-menu-olive {
  border-bottom-color: olive!important
}

.dropdown-menu-olive a:hover:before {
  color: olive!important
}

.dropdown-menu-teal {
  border-bottom-color: teal!important
}

.dropdown-menu-teal a:hover:before {
  color: teal!important
}

.dropdown-menu-green-bright {
  border-bottom-color: #2ECC71!important
}

.dropdown-menu-green-bright a:hover:before {
  color: #2ECC71!important
}

.dropdown-menu-sm {
  padding: 2px;
  font-size: 12px
}

.dropdown-menu-sm .dropdown-item {
  padding-left: 5px;
  padding-right: 5px
}

.dropdown-menu-lg {
  padding: 8px;
  font-size: 15px
}

.dropdown-menu-lg .dropdown-item {
  padding: 7.5px 15px
}

.dropdown-mega-menu {
  width: 100%
}

.dropdown-mega-menu .dropdown-menu,
.dropdown-mega-menu.dropdown-menu {
  min-width: 550px;
  max-width: 1200px;
  padding: 15px;
  width: 100%
}

@media (max-width:991px) {
  .dropdown-mega-menu .dropdown-menu,
  .dropdown-mega-menu.dropdown-menu {
    min-width: 150px
  }
}

.dropdown-mega-menu.nav-item {
  width: auto
}

.dropdown-effect-fade .dropdown-menu,
.dropdown-effect-fade.dropdown-menu {
  display: block;
  opacity: 0;
  -webkit-transition: all .4s ease;
  -moz-transition: all .4s ease;
  -ms-transition: all .4s ease;
  -o-transition: all .4s ease;
  transition: all .4s ease;
  visibility: hidden
}

.dropdown-effect-fade .open>.dropdown-menu,
.dropdown-effect-fade .show>.dropdown-menu,
.dropdown-effect-fade.open>.dropdown-menu,
.dropdown-effect-fade.show>.dropdown-menu,
.open>.dropdown-effect-fade.dropdown-menu,
.show>.dropdown-effect-fade.dropdown-menu {
  opacity: 1;
  display: block!important;
  visibility: visible!important
}

.dropdown-effect-fadeup .dropdown-menu,
.dropdown-effect-fadeup.dropdown-menu {
  display: block;
  opacity: 0;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  visibility: hidden;
  top: 140%
}

.dropdown-effect-fadeup .open>.dropdown-menu,
.dropdown-effect-fadeup .show>.dropdown-menu,
.dropdown-effect-fadeup.open>.dropdown-menu,
.dropdown-effect-fadeup.show>.dropdown-menu,
.open>.dropdown-effect-fadeup.dropdown-menu,
.show>.dropdown-effect-fadeup.dropdown-menu {
  opacity: 1;
  display: block!important;
  visibility: visible!important;
  top: 100%
}

.dropdown-effect-fadedown .dropdown-menu,
.dropdown-effect-fadedown.dropdown-menu {
  display: block;
  opacity: 0;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  visibility: hidden;
  top: 60%
}

.dropdown-effect-fadedown .open>.dropdown-menu,
.dropdown-effect-fadedown .show>.dropdown-menu,
.dropdown-effect-fadedown.open>.dropdown-menu,
.dropdown-effect-fadedown.show>.dropdown-menu,
.open>.dropdown-effect-fadedown.dropdown-menu,
.show>.dropdown-effect-fadedown.dropdown-menu {
  opacity: 1;
  display: block!important;
  visibility: visible!important;
  top: 100%
}

.dropdown-menu-arrow {
  margin-top: 15px!important
}

.dropdown-menu-arrow:after,
.dropdown-menu-arrow:before {
  bottom: 100%;
  left: 15px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none
}

.dropdown-menu-arrow:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #E6E6E6;
  border-width: 10px;
  margin-left: -10px
}

.dropdown-menu-arrow:before {
  border-color: rgba(194, 225, 245, 0);
  border-bottom-color: #E6E6E6;
  border-width: 14px;
  margin-left: -14px
}

.dropdown-menu-arrow.dropdown-menu-right {
  right: -15px
}

.dropdown-menu-arrow.dropdown-menu-right:after,
.dropdown-menu-arrow.dropdown-menu-right:before {
  right: 15px;
  left: auto
}

body {
  overflow-x: hidden
}

#highlighted {
  background-color: #E6E6E6;
  border-bottom: 1px solid rgba(27, 27, 27, .2);
  border-top: none;
  -webkit-transition: all .4s ease-out;
  -moz-transition: all .4s ease-out;
  -ms-transition: all .4s ease-out;
  -o-transition: all .4s ease-out;
  transition: all .4s ease-out;
  color: #1b1b1b
}

#highlighted::after {
  display: block;
  content: "";
  clear: both
}

#content {
  padding: 30px 0
}

#page-header,
.page-header {
  background-color: #fefefe;
  border-bottom: 1px solid #e8e8ee;
  padding: 30px 0
}

#page-header .breadcrumb,
.page-header .breadcrumb {
  padding: 0;
  margin: 0;
  background-color: transparent
}

#page-header .page-header-tabs,
.page-header .page-header-tabs {
  position: relative;
  bottom: -31px;
  margin-bottom: 0
}

body.page-translucent,
html.page-translucent {
  background: 0 0;
  background-color: rgba(255, 255, 255, .7)!important
}

body.page-translucent else,
html.page-translucent else {
  background-color: rgba(255, 255, 255, .7)
}

#content-below {
  background: #f7f7f7
}

@media (min-width:768px) {
  .sidebar-left,
  .sidebar-right {
    position: relative
  }
  .sidebar-left:after,
  .sidebar-right:after {
    height: 100%;
    width: 1px;
    background: #E6E6E6;
    content: '';
    position: absolute;
    right: 0;
    left: auto;
    top: 0;
    bottom: 0
  }
  .sidebar-left {
    padding-right: 30px
  }
  .sidebar-right {
    padding-left: 30px
  }
  .sidebar-right:after {
    right: 0;
    left: 0
  }
}

#footer {
  background: #292b2c;
  padding: 60px 0 30px;
  color: #A2A2A2;
  color: rgba(255, 255, 255, .8);
  border-top: 2px solid #55A79A
}

#footer .footer-link,
#footer .footer-links a {
  color: #CCC;
  color: rgba(255, 255, 255, .9)
}

#footer .footer-link:hover,
#footer .footer-links a:hover {
  color: #fff
}

#footer.footer-light {
  color: rgba(41, 43, 44, .8);
  background: #f7f7f7
}

#footer.footer-light .footer-link,
#footer.footer-light .footer-links a {
  color: rgba(41, 43, 44, .9)
}

#footer.footer-light .footer-link:hover,
#footer.footer-light .footer-links a:hover {
  color: #292b2c
}

#footer .container {
  position: relative
}

#footer.sticky {
  position: fixed;
  bottom: 0;
  top: auto!important;
  left: 0;
  right: 0
}

.page-loader {
  background: #fff;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999999
}

.page-loader .fl {
  left: 0!important;
  right: 0!important;
  width: 90px;
  margin: 0 auto
}

.alert-secondary {
  background-color: #fff;
  border-color: #ccc;
  color: #1b1b1b
}

.alert-secondary hr {
  border-top-color: #ccc
}

.alert-secondary .alert-link {
  color: #020202
}

.alert-primary {
  background-color: rgba(85, 167, 154, .25);
  border-color: rgba(85, 167, 154, .8);
  color: #33635c
}

.alert-primary hr {
  border-top-color: rgba(85, 167, 154, .85)
}

.alert-primary .alert-link {
  color: #33635c
}

.alert-inverse {
  background-color: #292b2c;
  border-color: #101112;
  color: #fff
}

.alert-inverse hr {
  border-top-color: #000
}

.alert-inverse .alert-link,
.alert-inverse .close:focus,
.alert-inverse .close:hover {
  color: #fff
}

.alert-green {
  background-color: #91c7be;
  border-color: #478c81;
  color: #3b746b
}

.alert-green hr {
  border-top-color: #44857b
}

.alert-green .alert-link,
.alert-green .close:focus,
.alert-green .close:hover {
  color: #fff
}

.alert-red {
  background-color: #e57052;
  border-color: #9b3218;
  color: #7c2813
}

.alert-red hr {
  border-top-color: #923016
}

.alert-red .alert-link,
.alert-red .close:focus,
.alert-red .close:hover {
  color: #fff
}

.alert-blue {
  background-color: #18eeff;
  border-color: #008792;
  color: #00666f
}

.alert-blue hr {
  border-top-color: #007e88
}

.alert-blue .alert-link,
.alert-blue .close:focus,
.alert-blue .close:hover {
  color: #fff
}

.alert-purple {
  background-color: #d6aed2;
  border-color: #a956a1;
  color: #924a8a
}

.alert-purple hr {
  border-top-color: #a3529b
}

.alert-purple .alert-link,
.alert-purple .close:focus,
.alert-purple .close:hover {
  color: #fff
}

.alert-pink {
  background-color: #ec5280;
  border-color: #a7123f;
  color: #870f33
}

.alert-pink hr {
  border-top-color: #9e113c
}

.alert-pink .alert-link,
.alert-pink .close:focus,
.alert-pink .close:hover {
  color: #fff
}

.alert-orange {
  background-color: #efae74;
  border-color: #c96a17;
  color: #a85913
}

.alert-orange hr {
  border-top-color: #bf6516
}

.alert-orange .alert-link,
.alert-orange .close:focus,
.alert-orange .close:hover {
  color: #fff
}

.alert-lime {
  background-color: #d1ea91;
  border-color: #a0d027;
  color: #89b221
}

.alert-lime hr {
  border-top-color: #9ac825
}

.alert-lime .alert-link,
.alert-lime .close:focus,
.alert-lime .close:hover {
  color: #fff
}

.alert-blue-dark {
  background-color: #579;
  border-color: #253544;
  color: #19232d
}

.alert-blue-dark hr {
  border-top-color: #22303d
}

.alert-blue-dark .alert-link,
.alert-blue-dark .close:focus,
.alert-blue-dark .close:hover {
  color: #fff
}

.alert-red-dark {
  background-color: #ea2148;
  border-color: #7c0c21;
  color: #5b0818
}

.alert-red-dark hr {
  border-top-color: #720b1f
}

.alert-red-dark .alert-link,
.alert-red-dark .close:focus,
.alert-red-dark .close:hover {
  color: #fff
}

.alert-brown {
  background-color: #c19168;
  border-color: #744f30;
  color: #5b3e26
}

.alert-brown hr {
  border-top-color: #6d4a2d
}

.alert-brown .alert-link,
.alert-brown .close:focus,
.alert-brown .close:hover {
  color: #fff
}

.alert-cyan-dark {
  background-color: #00e7e7;
  border-color: #006262;
  color: #003f3f
}

.alert-cyan-dark hr {
  border-top-color: #005858
}

.alert-cyan-dark .alert-link,
.alert-cyan-dark .close:focus,
.alert-cyan-dark .close:hover {
  color: #fff
}

.alert-yellow {
  background-color: #f4d149;
  border-color: #ae8d0b;
  color: #8c7209
}

.alert-yellow hr {
  border-top-color: #a4850a
}

.alert-yellow .alert-link,
.alert-yellow .close:focus,
.alert-yellow .close:hover {
  color: #fff
}

.alert-slate {
  background-color: #8c9baa;
  border-color: #4c5967;
  color: #3d4752
}

.alert-slate hr {
  border-top-color: #475461
}

.alert-slate .alert-link,
.alert-slate .close:focus,
.alert-slate .close:hover {
  color: #fff
}

.alert-olive {
  background-color: #dcdc00;
  border-color: #575700;
  color: #343400
}

.alert-olive hr {
  border-top-color: #4d4d00
}

.alert-olive .alert-link,
.alert-olive .close:focus,
.alert-olive .close:hover {
  color: #fff
}

.alert-teal {
  background-color: #00dcdc;
  border-color: #005757;
  color: #003434
}

.alert-teal hr {
  border-top-color: #004d4d
}

.alert-teal .alert-link,
.alert-teal .close:focus,
.alert-teal .close:hover {
  color: #fff
}

.alert-green-bright {
  background-color: #76e0a3;
  border-color: #26ab5f;
  color: #208e4e
}

.alert-green-bright hr {
  border-top-color: #25a25a
}

.alert-green-bright .alert-link,
.alert-green-bright .close:focus,
.alert-green-bright .close:hover {
  color: #fff
}

.btn {
  font-size: 14px;
  line-height: 1.35;
  border-radius: 2px;
  border-width: 1px;
  border-style: solid
}

.btn span.caret {
  display: inline-block
}

.btn.font-weight-bold {
  font-weight: 700
}

.btn.font-weight-normal {
  font-weight: 400
}

.btn.focus,
.btn:focus {
  box-shadow: none
}

.btn-default {
  color: #4B4B4B;
  background-color: #F5F5F5;
  border-color: #E5E5E5;
  border-left: none;
  border-right: none;
  border-top: none
}

.btn-default.focus,
.btn-default:focus {
  color: #4B4B4B;
  background-color: #dcdcdc;
  border-color: #a5a5a5
}

.btn-default:hover {
  color: #4B4B4B;
  background-color: #dcdcdc;
  border-color: #c6c6c6
}

.btn-default.active,
.btn-default:active,
.open>.btn-default.dropdown-toggle,
.show>.btn-default.dropdown-toggle {
  color: #4B4B4B;
  background-color: #dcdcdc;
  border-color: #c6c6c6;
  background-image: none
}

.btn-default.active.focus,
.btn-default.active:focus,
.btn-default.active:hover,
.btn-default:active.focus,
.btn-default:active:focus,
.btn-default:active:hover,
.open>.btn-default.dropdown-toggle.focus,
.open>.btn-default.dropdown-toggle:focus,
.open>.btn-default.dropdown-toggle:hover,
.show>.btn-default.dropdown-toggle.focus,
.show>.btn-default.dropdown-toggle:focus,
.show>.btn-default.dropdown-toggle:hover {
  color: #4B4B4B;
  background-color: #cacaca;
  border-color: #a5a5a5
}

.btn-default.disabled,
.btn-default.disabled.focus,
.btn-default.disabled:focus,
.btn-default.disabled:hover,
.btn-default:disabled,
.btn-default:disabled.focus,
.btn-default:disabled:focus,
.btn-default:disabled:hover,
.btn-default[disabled],
.btn-default[disabled].focus,
.btn-default[disabled]:focus,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default,
fieldset[disabled] .btn-default.focus,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default:hover {
  background-color: #F5F5F5;
  border-color: #E5E5E5
}

.btn-default .badge {
  color: #F5F5F5;
  background-color: #4B4B4B
}

.btn-inverse {
  color: #fff;
  background-color: #383838
}

.btn-inverse.focus,
.btn-inverse:focus,
.btn-inverse:hover {
  color: #fff;
  background-color: #1f1f1f;
  border-color: #000
}

.btn-inverse.active,
.btn-inverse:active,
.open>.btn-inverse.dropdown-toggle,
.show>.btn-inverse.dropdown-toggle {
  color: #fff;
  background-color: #1f1f1f;
  border-color: #000;
  background-image: none
}

.btn-inverse.active.focus,
.btn-inverse.active:focus,
.btn-inverse.active:hover,
.btn-inverse:active.focus,
.btn-inverse:active:focus,
.btn-inverse:active:hover,
.open>.btn-inverse.dropdown-toggle.focus,
.open>.btn-inverse.dropdown-toggle:focus,
.open>.btn-inverse.dropdown-toggle:hover,
.show>.btn-inverse.dropdown-toggle.focus,
.show>.btn-inverse.dropdown-toggle:focus,
.show>.btn-inverse.dropdown-toggle:hover {
  color: #fff;
  background-color: #0d0d0d;
  border-color: #000
}

.btn-inverse.disabled,
.btn-inverse.disabled.focus,
.btn-inverse.disabled:focus,
.btn-inverse.disabled:hover,
.btn-inverse:disabled,
.btn-inverse:disabled.focus,
.btn-inverse:disabled:focus,
.btn-inverse:disabled:hover,
.btn-inverse[disabled],
.btn-inverse[disabled].focus,
.btn-inverse[disabled]:focus,
.btn-inverse[disabled]:hover,
fieldset[disabled] .btn-inverse,
fieldset[disabled] .btn-inverse.focus,
fieldset[disabled] .btn-inverse:focus,
fieldset[disabled] .btn-inverse:hover {
  background-color: #383838;
  border-color: #000
}

.btn-inverse .badge,
.btn-outline-inverse {
  color: #383838;
  background-color: #fff
}

.btn-outline-inverse {
  border: 1px solid #383838
}

.btn-outline-inverse.active,
.btn-outline-inverse.active:focus,
.btn-outline-inverse.focus,
.btn-outline-inverse:active,
.btn-outline-inverse:active:focus,
.btn-outline-inverse:focus,
.btn-outline-inverse:hover,
.show>.btn-outline-inverse.dropdown-toggle {
  background-color: #383838;
  color: #fff
}

.btn-outline-white {
  border: 1px solid #fff;
  color: #fff;
  background: 0 0
}

.btn-group-lg>.btn,
.btn-group-sm>.btn,
.btn-icon.btn,
.btn-lg,
.btn-sm,
.btn-xl,
.btn-xlg,
.form-control-lg,
.input-group-addon,
.input-group-addon.form-control-lg,
.input-group-lg>.form-control,
.input-group-lg>.input-group-addon,
.input-group-lg>.input-group-btn>.btn,
.input-group-lg>.input-group-btn>.input-group-addon.btn {
  border-radius: 2px
}

.btn-outline-white.active,
.btn-outline-white.active:focus,
.btn-outline-white.focus,
.btn-outline-white:active,
.btn-outline-white:active:focus,
.btn-outline-white:focus,
.btn-outline-white:hover,
.show>.btn-outline-white.dropdown-toggle {
  color: #383838;
  background-color: #fff
}

.btn-danger:focus,
.btn-info:focus,
.btn-inverse:focus,
.btn-primary:focus,
.btn-success:focus,
.btn-warning:focus {
  color: #fff
}

.btn-block {
  display: block
}

.btn-icon.btn {
  font-size: 20px;
  text-align: center;
  padding: 0;
  height: 38px;
  width: 38px;
  line-height: 34px
}

.btn-icon.btn.btn-sm {
  height: 30px;
  width: 30px;
  line-height: 24px;
  font-size: 18px;
  padding: 0
}

.btn-icon.btn.btn-lg {
  height: 46px;
  width: 46px;
  line-height: 38px;
  font-size: 24px;
  padding: 0
}

.btn-icon.btn.btn-xlg {
  height: 60px;
  width: 60px;
  line-height: 54px;
  font-size: 28px;
  padding: 0
}

.btn-icon.btn.btn-sm.btn-rounded {
  font-size: 16px;
  line-height: 22px
}

.input-group-rounded .btn-icon.btn {
  line-height: 20px
}

.btn-xl,
.btn-xlg {
  padding: 15px 30px;
  font-size: 1.5rem;
  word-wrap: break-word
}

.btn-sm {
  font-size: 12px;
  line-height: 1.5;
  padding: .25rem .5rem
}

.btn-lg {
  font-size: 18px;
  padding: 10px 14px
}

.input-group-lg>.input-group-btn>.btn-icon.btn {
  height: 51px!important;
  width: 51px!important
}

.input-group-btn:not(:last-child)>.btn,
.input-group-btn:not(:last-child)>.btn-group {
  margin-right: -2px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0
}

.btn-primary {
  border-color: #3b746b
}

.btn-info {
  border-color: #28a1c5
}

.btn-success {
  border-color: #3d8b3d
}

.btn-warning {
  border-color: #df8a13
}

.btn-danger {
  border-color: #b52b27
}

.btn-inverse {
  border-color: #040404
}

.btn-secondary {
  border-color: #d9d9d9
}

.btn-secondary:focus {
  color: #1b1b1b
}

.btn-info.btn-invert {
  background-color: #31b0d5
}

.btn-info.btn-invert:focus,
.btn-info.btn-invert:hover {
  background-color: #5bc0de
}

.btn-success.btn-invert {
  background-color: #449d44
}

.btn-success.btn-invert:focus,
.btn-success.btn-invert:hover {
  background-color: #5cb85c
}

.btn-warning.btn-invert {
  background-color: #ec971f
}

.btn-warning.btn-invert:focus,
.btn-warning.btn-invert:hover {
  background-color: #f0ad4e
}

.btn-danger.btn-invert {
  background-color: #c9302c
}

.btn-danger.btn-invert:focus,
.btn-danger.btn-invert:hover {
  background-color: #d9534f
}

.btn-inverse.btn-invert {
  background-color: #101112
}

.btn-inverse.btn-invert:focus,
.btn-inverse.btn-invert:hover {
  background-color: #292b2c
}

.btn-secondary.btn-invert {
  background-color: #e6e6e6
}

.btn-secondary.btn-invert:focus,
.btn-secondary.btn-invert:hover {
  background-color: #fff
}

.btn-link,
.btn-link.disabled,
.btn-link.disabled.focus,
.btn-link.disabled:focus,
.btn-link.disabled:hover,
.btn-link:disabled,
.btn-link:disabled.focus,
.btn-link:disabled:focus,
.btn-link:disabled:hover,
.btn-link[disabled],
.btn-link[disabled].focus,
.btn-link[disabled]:focus,
.btn-link[disabled]:hover,
fieldset[disabled] .btn-link,
fieldset[disabled] .btn-link.focus,
fieldset[disabled] .btn-link:focus,
fieldset[disabled] .btn-link:hover {
  background-color: transparent;
  border-color: transparent
}

.btn-shadow {
  box-shadow: rgba(23, 43, 99, .2) 0 10px 18px
}

.btn-rounded-sm {
  border-radius: .25rem
}

.btn-group-lg>.btn.btn-rounded,
.btn-group-sm>.btn.btn-rounded,
.btn-rounded {
  border-radius: 30px
}

.btn-rounded.btn-icon {
  border-radius: 100%
}

.btn-link {
  color: rgba(78, 78, 78, .8)
}

.btn-link.active,
.btn-link:active,
.open>.dropdown-toggle .btn-link {
  background-image: none
}

.btn-link .badge {
  color: transparent;
  background-color: rgba(78, 78, 78, .8)
}

.btn-link.focus,
.btn-link:focus,
.btn-link:hover {
  color: rgba(27, 27, 27, .8);
  text-decoration: none
}

.btn-link.active,
.btn-link.active.focus,
.btn-link.active:focus,
.btn-link.active:hover,
.btn-link:active,
.btn-link:active.focus,
.btn-link:active:focus,
.btn-link:active:hover,
.open>.dropdown-toggle .btn-link,
.open>.dropdown-toggle .btn-link.focus,
.open>.dropdown-toggle .btn-link:focus,
.open>.dropdown-toggle .btn-link:hover,
.show>.dropdown-toggle .btn-link,
.show>.dropdown-toggle .btn-link.focus,
.show>.dropdown-toggle .btn-link:focus,
.show>.dropdown-toggle .btn-link:hover {
  color: rgba(27, 27, 27, .8)
}

.btn-info.btn-link,
.btn-info.btn-link.active.focus,
.btn-info.btn-link.active:focus,
.btn-info.btn-link.active:hover,
.btn-info.btn-link.focus,
.btn-info.btn-link:active.focus,
.btn-info.btn-link:active:focus,
.btn-info.btn-link:active:hover,
.btn-info.btn-link:focus,
.btn-info.btn-link:hover,
.open>.btn-info.btn-link.dropdown-toggle.focus,
.open>.btn-info.btn-link.dropdown-toggle:focus,
.open>.btn-info.btn-link.dropdown-toggle:hover,
.show>.btn-info.btn-link.dropdown-toggle.focus,
.show>.btn-info.btn-link.dropdown-toggle:focus,
.show>.btn-info.btn-link.dropdown-toggle:hover {
  color: #5bc0de;
  background-color: transparent;
  border-color: transparent
}

.btn-info.btn-link.active,
.btn-info.btn-link:active,
.open>.btn-info.btn-link.dropdown-toggle,
.show>.btn-info.btn-link.dropdown-toggle {
  color: #5bc0de;
  background-color: transparent;
  border-color: transparent;
  background-image: none
}

.btn-info.btn-link.disabled,
.btn-info.btn-link.disabled.focus,
.btn-info.btn-link.disabled:focus,
.btn-info.btn-link.disabled:hover,
.btn-info.btn-link:disabled,
.btn-info.btn-link:disabled.focus,
.btn-info.btn-link:disabled:focus,
.btn-info.btn-link:disabled:hover,
.btn-info.btn-link[disabled],
.btn-info.btn-link[disabled].focus,
.btn-info.btn-link[disabled]:focus,
.btn-info.btn-link[disabled]:hover,
fieldset[disabled] .btn-info.btn-link,
fieldset[disabled] .btn-info.btn-link.focus,
fieldset[disabled] .btn-info.btn-link:focus,
fieldset[disabled] .btn-info.btn-link:hover {
  background-color: transparent;
  border-color: transparent
}

.btn-info.btn-link .badge {
  color: transparent;
  background-color: #5bc0de
}

.btn-success.btn-link,
.btn-success.btn-link.focus,
.btn-success.btn-link:focus,
.btn-success.btn-link:hover {
  color: #5cb85c;
  background-color: transparent;
  border-color: transparent
}

.btn-success.btn-link.active,
.btn-success.btn-link:active,
.open>.btn-success.btn-link.dropdown-toggle,
.show>.btn-success.btn-link.dropdown-toggle {
  color: #5cb85c;
  background-color: transparent;
  border-color: transparent;
  background-image: none
}

.btn-success.btn-link.active.focus,
.btn-success.btn-link.active:focus,
.btn-success.btn-link.active:hover,
.btn-success.btn-link:active.focus,
.btn-success.btn-link:active:focus,
.btn-success.btn-link:active:hover,
.open>.btn-success.btn-link.dropdown-toggle.focus,
.open>.btn-success.btn-link.dropdown-toggle:focus,
.open>.btn-success.btn-link.dropdown-toggle:hover,
.show>.btn-success.btn-link.dropdown-toggle.focus,
.show>.btn-success.btn-link.dropdown-toggle:focus,
.show>.btn-success.btn-link.dropdown-toggle:hover {
  color: #5cb85c;
  background-color: transparent;
  border-color: transparent
}

.btn-success.btn-link.disabled,
.btn-success.btn-link.disabled.focus,
.btn-success.btn-link.disabled:focus,
.btn-success.btn-link.disabled:hover,
.btn-success.btn-link:disabled,
.btn-success.btn-link:disabled.focus,
.btn-success.btn-link:disabled:focus,
.btn-success.btn-link:disabled:hover,
.btn-success.btn-link[disabled],
.btn-success.btn-link[disabled].focus,
.btn-success.btn-link[disabled]:focus,
.btn-success.btn-link[disabled]:hover,
fieldset[disabled] .btn-success.btn-link,
fieldset[disabled] .btn-success.btn-link.focus,
fieldset[disabled] .btn-success.btn-link:focus,
fieldset[disabled] .btn-success.btn-link:hover {
  background-color: transparent;
  border-color: transparent
}

.btn-success.btn-link .badge {
  color: transparent;
  background-color: #5cb85c
}

.btn-warning.btn-link,
.btn-warning.btn-link.focus,
.btn-warning.btn-link:focus,
.btn-warning.btn-link:hover {
  color: #f0ad4e;
  background-color: transparent;
  border-color: transparent
}

.btn-warning.btn-link.active,
.btn-warning.btn-link:active,
.open>.btn-warning.btn-link.dropdown-toggle,
.show>.btn-warning.btn-link.dropdown-toggle {
  color: #f0ad4e;
  background-color: transparent;
  border-color: transparent;
  background-image: none
}

.btn-warning.btn-link.active.focus,
.btn-warning.btn-link.active:focus,
.btn-warning.btn-link.active:hover,
.btn-warning.btn-link:active.focus,
.btn-warning.btn-link:active:focus,
.btn-warning.btn-link:active:hover,
.open>.btn-warning.btn-link.dropdown-toggle.focus,
.open>.btn-warning.btn-link.dropdown-toggle:focus,
.open>.btn-warning.btn-link.dropdown-toggle:hover,
.show>.btn-warning.btn-link.dropdown-toggle.focus,
.show>.btn-warning.btn-link.dropdown-toggle:focus,
.show>.btn-warning.btn-link.dropdown-toggle:hover {
  color: #f0ad4e;
  background-color: transparent;
  border-color: transparent
}

.btn-warning.btn-link.disabled,
.btn-warning.btn-link.disabled.focus,
.btn-warning.btn-link.disabled:focus,
.btn-warning.btn-link.disabled:hover,
.btn-warning.btn-link:disabled,
.btn-warning.btn-link:disabled.focus,
.btn-warning.btn-link:disabled:focus,
.btn-warning.btn-link:disabled:hover,
.btn-warning.btn-link[disabled],
.btn-warning.btn-link[disabled].focus,
.btn-warning.btn-link[disabled]:focus,
.btn-warning.btn-link[disabled]:hover,
fieldset[disabled] .btn-warning.btn-link,
fieldset[disabled] .btn-warning.btn-link.focus,
fieldset[disabled] .btn-warning.btn-link:focus,
fieldset[disabled] .btn-warning.btn-link:hover {
  background-color: transparent;
  border-color: transparent
}

.btn-warning.btn-link .badge {
  color: transparent;
  background-color: #f0ad4e
}

.btn-danger.btn-link,
.btn-danger.btn-link.focus,
.btn-danger.btn-link:focus,
.btn-danger.btn-link:hover {
  color: #d9534f;
  background-color: transparent;
  border-color: transparent
}

.btn-danger.btn-link.active,
.btn-danger.btn-link:active,
.open>.btn-danger.btn-link.dropdown-toggle,
.show>.btn-danger.btn-link.dropdown-toggle {
  color: #d9534f;
  background-color: transparent;
  border-color: transparent;
  background-image: none
}

.btn-danger.btn-link.active.focus,
.btn-danger.btn-link.active:focus,
.btn-danger.btn-link.active:hover,
.btn-danger.btn-link:active.focus,
.btn-danger.btn-link:active:focus,
.btn-danger.btn-link:active:hover,
.open>.btn-danger.btn-link.dropdown-toggle.focus,
.open>.btn-danger.btn-link.dropdown-toggle:focus,
.open>.btn-danger.btn-link.dropdown-toggle:hover,
.show>.btn-danger.btn-link.dropdown-toggle.focus,
.show>.btn-danger.btn-link.dropdown-toggle:focus,
.show>.btn-danger.btn-link.dropdown-toggle:hover {
  color: #d9534f;
  background-color: transparent;
  border-color: transparent
}

.btn-danger.btn-link.disabled,
.btn-danger.btn-link.disabled.focus,
.btn-danger.btn-link.disabled:focus,
.btn-danger.btn-link.disabled:hover,
.btn-danger.btn-link:disabled,
.btn-danger.btn-link:disabled.focus,
.btn-danger.btn-link:disabled:focus,
.btn-danger.btn-link:disabled:hover,
.btn-danger.btn-link[disabled],
.btn-danger.btn-link[disabled].focus,
.btn-danger.btn-link[disabled]:focus,
.btn-danger.btn-link[disabled]:hover,
fieldset[disabled] .btn-danger.btn-link,
fieldset[disabled] .btn-danger.btn-link.focus,
fieldset[disabled] .btn-danger.btn-link:focus,
fieldset[disabled] .btn-danger.btn-link:hover {
  background-color: transparent;
  border-color: transparent
}

.btn-danger.btn-link .badge {
  color: transparent;
  background-color: #d9534f
}

.btn-inverse.btn-link,
.btn-inverse.btn-link.focus,
.btn-inverse.btn-link:focus,
.btn-inverse.btn-link:hover {
  color: #292b2c;
  background-color: transparent;
  border-color: transparent
}

.btn-inverse.btn-link.active,
.btn-inverse.btn-link:active,
.open>.btn-inverse.btn-link.dropdown-toggle,
.show>.btn-inverse.btn-link.dropdown-toggle {
  color: #292b2c;
  background-color: transparent;
  border-color: transparent;
  background-image: none
}

.btn-inverse.btn-link.active.focus,
.btn-inverse.btn-link.active:focus,
.btn-inverse.btn-link.active:hover,
.btn-inverse.btn-link:active.focus,
.btn-inverse.btn-link:active:focus,
.btn-inverse.btn-link:active:hover,
.open>.btn-inverse.btn-link.dropdown-toggle.focus,
.open>.btn-inverse.btn-link.dropdown-toggle:focus,
.open>.btn-inverse.btn-link.dropdown-toggle:hover,
.show>.btn-inverse.btn-link.dropdown-toggle.focus,
.show>.btn-inverse.btn-link.dropdown-toggle:focus,
.show>.btn-inverse.btn-link.dropdown-toggle:hover {
  color: #292b2c;
  background-color: transparent;
  border-color: transparent
}

.btn-inverse.btn-link.disabled,
.btn-inverse.btn-link.disabled.focus,
.btn-inverse.btn-link.disabled:focus,
.btn-inverse.btn-link.disabled:hover,
.btn-inverse.btn-link:disabled,
.btn-inverse.btn-link:disabled.focus,
.btn-inverse.btn-link:disabled:focus,
.btn-inverse.btn-link:disabled:hover,
.btn-inverse.btn-link[disabled],
.btn-inverse.btn-link[disabled].focus,
.btn-inverse.btn-link[disabled]:focus,
.btn-inverse.btn-link[disabled]:hover,
fieldset[disabled] .btn-inverse.btn-link,
fieldset[disabled] .btn-inverse.btn-link.focus,
fieldset[disabled] .btn-inverse.btn-link:focus,
fieldset[disabled] .btn-inverse.btn-link:hover {
  background-color: transparent;
  border-color: transparent
}

.btn-inverse.btn-link .badge {
  color: transparent;
  background-color: #292b2c
}

.btn-secondary.btn-link,
.btn-secondary.btn-link.focus,
.btn-secondary.btn-link:focus,
.btn-secondary.btn-link:hover {
  color: #fff;
  background-color: transparent;
  border-color: transparent
}

.btn-secondary.btn-link.active,
.btn-secondary.btn-link:active,
.open>.btn-secondary.btn-link.dropdown-toggle,
.show>.btn-secondary.btn-link.dropdown-toggle {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
  background-image: none
}

.btn-secondary.btn-link.active.focus,
.btn-secondary.btn-link.active:focus,
.btn-secondary.btn-link.active:hover,
.btn-secondary.btn-link:active.focus,
.btn-secondary.btn-link:active:focus,
.btn-secondary.btn-link:active:hover,
.open>.btn-secondary.btn-link.dropdown-toggle.focus,
.open>.btn-secondary.btn-link.dropdown-toggle:focus,
.open>.btn-secondary.btn-link.dropdown-toggle:hover,
.show>.btn-secondary.btn-link.dropdown-toggle.focus,
.show>.btn-secondary.btn-link.dropdown-toggle:focus,
.show>.btn-secondary.btn-link.dropdown-toggle:hover {
  color: #fff;
  background-color: transparent;
  border-color: transparent
}

.btn-secondary.btn-link.disabled,
.btn-secondary.btn-link.disabled.focus,
.btn-secondary.btn-link.disabled:focus,
.btn-secondary.btn-link.disabled:hover,
.btn-secondary.btn-link:disabled,
.btn-secondary.btn-link:disabled.focus,
.btn-secondary.btn-link:disabled:focus,
.btn-secondary.btn-link:disabled:hover,
.btn-secondary.btn-link[disabled],
.btn-secondary.btn-link[disabled].focus,
.btn-secondary.btn-link[disabled]:focus,
.btn-secondary.btn-link[disabled]:hover,
fieldset[disabled] .btn-secondary.btn-link,
fieldset[disabled] .btn-secondary.btn-link.focus,
fieldset[disabled] .btn-secondary.btn-link:focus,
fieldset[disabled] .btn-secondary.btn-link:hover {
  background-color: transparent;
  border-color: transparent
}

.btn-secondary.btn-link .badge {
  color: transparent;
  background-color: #fff
}

.btn-green {
  color: #fff;
  background-color: #55A79A;
  border-color: #55A79A
}

.btn-green.focus,
.btn-green:focus {
  color: #fff;
  background-color: #44857b;
  border-color: #2a534c
}

.btn-green:hover {
  color: #fff;
  background-color: #44857b;
  border-color: #407e75
}

.btn-green.active,
.btn-green:active,
.open>.btn-green.dropdown-toggle,
.show>.btn-green.dropdown-toggle {
  color: #fff;
  background-color: #44857b;
  border-color: #407e75;
  background-image: none
}

.btn-green.active.focus,
.btn-green.active:focus,
.btn-green.active:hover,
.btn-green:active.focus,
.btn-green:active:focus,
.btn-green:active:hover,
.open>.btn-green.dropdown-toggle.focus,
.open>.btn-green.dropdown-toggle:focus,
.open>.btn-green.dropdown-toggle:hover,
.show>.btn-green.dropdown-toggle.focus,
.show>.btn-green.dropdown-toggle:focus,
.show>.btn-green.dropdown-toggle:hover {
  color: #fff;
  background-color: #386e65;
  border-color: #2a534c
}

.btn-green.disabled,
.btn-green.disabled.focus,
.btn-green.disabled:focus,
.btn-green.disabled:hover,
.btn-green:disabled,
.btn-green:disabled.focus,
.btn-green:disabled:focus,
.btn-green:disabled:hover,
.btn-green[disabled],
.btn-green[disabled].focus,
.btn-green[disabled]:focus,
.btn-green[disabled]:hover,
fieldset[disabled] .btn-green,
fieldset[disabled] .btn-green.focus,
fieldset[disabled] .btn-green:focus,
fieldset[disabled] .btn-green:hover {
  background-color: #55A79A;
  border-color: #55A79A
}

.btn-green .badge {
  color: #55A79A;
  background-color: #fff
}

.btn-outline-green {
  color: #55A79A;
  background-image: none;
  background-color: transparent;
  border-color: #55A79A
}

.btn-outline-green:hover {
  color: #fff;
  background-color: #55A79A;
  border-color: #55A79A
}

.btn-outline-green.focus,
.btn-outline-green:focus {
  box-shadow: 0 0 0 2px rgba(85, 167, 154, .5)
}

.btn-outline-green.disabled,
.btn-outline-green:disabled {
  color: #55A79A;
  background-color: transparent
}

.btn-outline-green.active,
.btn-outline-green:active,
.show>.btn-outline-green.dropdown-toggle {
  color: #fff;
  background-color: #55A79A;
  border-color: #55A79A
}

.btn-green.btn-invert {
  background-color: #44857b
}

.btn-green.btn-invert:focus,
.btn-green.btn-invert:hover {
  background-color: #55A79A
}

.btn-red {
  color: #fff;
  background-color: #BE3E1D;
  border-color: #BE3E1D
}

.btn-red.focus,
.btn-red:focus {
  color: #fff;
  background-color: #923016;
  border-color: #4f1a0c
}

.btn-red:hover {
  color: #fff;
  background-color: #923016;
  border-color: #892d15
}

.btn-red.active,
.btn-red:active,
.open>.btn-red.dropdown-toggle,
.show>.btn-red.dropdown-toggle {
  color: #fff;
  background-color: #923016;
  border-color: #892d15;
  background-image: none
}

.btn-red.active.focus,
.btn-red.active:focus,
.btn-red.active:hover,
.btn-red:active.focus,
.btn-red:active:focus,
.btn-red:active:hover,
.open>.btn-red.dropdown-toggle.focus,
.open>.btn-red.dropdown-toggle:focus,
.open>.btn-red.dropdown-toggle:hover,
.show>.btn-red.dropdown-toggle.focus,
.show>.btn-red.dropdown-toggle:focus,
.show>.btn-red.dropdown-toggle:hover {
  color: #fff;
  background-color: #732512;
  border-color: #4f1a0c
}

.btn-red.disabled,
.btn-red.disabled.focus,
.btn-red.disabled:focus,
.btn-red.disabled:hover,
.btn-red:disabled,
.btn-red:disabled.focus,
.btn-red:disabled:focus,
.btn-red:disabled:hover,
.btn-red[disabled],
.btn-red[disabled].focus,
.btn-red[disabled]:focus,
.btn-red[disabled]:hover,
fieldset[disabled] .btn-red,
fieldset[disabled] .btn-red.focus,
fieldset[disabled] .btn-red:focus,
fieldset[disabled] .btn-red:hover {
  background-color: #BE3E1D;
  border-color: #BE3E1D
}

.btn-red .badge {
  color: #BE3E1D;
  background-color: #fff
}

.btn-outline-red {
  color: #BE3E1D;
  background-image: none;
  background-color: transparent;
  border-color: #BE3E1D
}

.btn-outline-red:hover {
  color: #fff;
  background-color: #BE3E1D;
  border-color: #BE3E1D
}

.btn-outline-red.focus,
.btn-outline-red:focus {
  box-shadow: 0 0 0 2px rgba(190, 62, 29, .5)
}

.btn-outline-red.disabled,
.btn-outline-red:disabled {
  color: #BE3E1D;
  background-color: transparent
}

.btn-outline-red.active,
.btn-outline-red:active,
.show>.btn-outline-red.dropdown-toggle {
  color: #fff;
  background-color: #BE3E1D;
  border-color: #BE3E1D
}

.btn-red.btn-invert {
  background-color: #923016
}

.btn-red.btn-invert:focus,
.btn-red.btn-invert:hover {
  background-color: #BE3E1D
}

.btn-blue {
  color: #fff;
  background-color: #00ADBB;
  border-color: #00ADBB
}

.btn-blue.focus,
.btn-blue:focus {
  color: #fff;
  background-color: #007e88;
  border-color: #00373c
}

.btn-blue:hover {
  color: #fff;
  background-color: #007e88;
  border-color: #00747e
}

.btn-blue.active,
.btn-blue:active,
.open>.btn-blue.dropdown-toggle,
.show>.btn-blue.dropdown-toggle {
  color: #fff;
  background-color: #007e88;
  border-color: #00747e;
  background-image: none
}

.btn-blue.active.focus,
.btn-blue.active:focus,
.btn-blue.active:hover,
.btn-blue:active.focus,
.btn-blue:active:focus,
.btn-blue:active:hover,
.open>.btn-blue.dropdown-toggle.focus,
.open>.btn-blue.dropdown-toggle:focus,
.open>.btn-blue.dropdown-toggle:hover,
.show>.btn-blue.dropdown-toggle.focus,
.show>.btn-blue.dropdown-toggle:focus,
.show>.btn-blue.dropdown-toggle:hover {
  color: #fff;
  background-color: #005d64;
  border-color: #00373c
}

.btn-blue.disabled,
.btn-blue.disabled.focus,
.btn-blue.disabled:focus,
.btn-blue.disabled:hover,
.btn-blue:disabled,
.btn-blue:disabled.focus,
.btn-blue:disabled:focus,
.btn-blue:disabled:hover,
.btn-blue[disabled],
.btn-blue[disabled].focus,
.btn-blue[disabled]:focus,
.btn-blue[disabled]:hover,
fieldset[disabled] .btn-blue,
fieldset[disabled] .btn-blue.focus,
fieldset[disabled] .btn-blue:focus,
fieldset[disabled] .btn-blue:hover {
  background-color: #00ADBB;
  border-color: #00ADBB
}

.btn-blue .badge {
  color: #00ADBB;
  background-color: #fff
}

.btn-outline-blue {
  color: #00ADBB;
  background-image: none;
  background-color: transparent;
  border-color: #00ADBB
}

.btn-outline-blue:hover {
  color: #fff;
  background-color: #00ADBB;
  border-color: #00ADBB
}

.btn-outline-blue.focus,
.btn-outline-blue:focus {
  box-shadow: 0 0 0 2px rgba(0, 173, 187, .5)
}

.btn-outline-blue.disabled,
.btn-outline-blue:disabled {
  color: #00ADBB;
  background-color: transparent
}

.btn-outline-blue.active,
.btn-outline-blue:active,
.show>.btn-outline-blue.dropdown-toggle {
  color: #fff;
  background-color: #00ADBB;
  border-color: #00ADBB
}

.btn-blue.btn-invert {
  background-color: #007e88
}

.btn-blue.btn-invert:focus,
.btn-blue.btn-invert:hover {
  background-color: #00ADBB
}

.btn-purple {
  color: #fff;
  background-color: #b771b0;
  border-color: #b771b0
}

.btn-purple.focus,
.btn-purple:focus {
  color: #fff;
  background-color: #a3529b;
  border-color: #70396a
}

.btn-purple:hover {
  color: #fff;
  background-color: #a3529b;
  border-color: #9c4f94
}

.btn-purple.active,
.btn-purple:active,
.open>.btn-purple.dropdown-toggle,
.show>.btn-purple.dropdown-toggle {
  color: #fff;
  background-color: #a3529b;
  border-color: #9c4f94;
  background-image: none
}

.btn-purple.active.focus,
.btn-purple.active:focus,
.btn-purple.active:hover,
.btn-purple:active.focus,
.btn-purple:active:focus,
.btn-purple:active:hover,
.open>.btn-purple.dropdown-toggle.focus,
.open>.btn-purple.dropdown-toggle:focus,
.open>.btn-purple.dropdown-toggle:hover,
.show>.btn-purple.dropdown-toggle.focus,
.show>.btn-purple.dropdown-toggle:focus,
.show>.btn-purple.dropdown-toggle:hover {
  color: #fff;
  background-color: #8b4684;
  border-color: #70396a
}

.btn-purple.disabled,
.btn-purple.disabled.focus,
.btn-purple.disabled:focus,
.btn-purple.disabled:hover,
.btn-purple:disabled,
.btn-purple:disabled.focus,
.btn-purple:disabled:focus,
.btn-purple:disabled:hover,
.btn-purple[disabled],
.btn-purple[disabled].focus,
.btn-purple[disabled]:focus,
.btn-purple[disabled]:hover,
fieldset[disabled] .btn-purple,
fieldset[disabled] .btn-purple.focus,
fieldset[disabled] .btn-purple:focus,
fieldset[disabled] .btn-purple:hover {
  background-color: #b771b0;
  border-color: #b771b0
}

.btn-purple .badge {
  color: #b771b0;
  background-color: #fff
}

.btn-outline-purple {
  color: #b771b0;
  background-image: none;
  background-color: transparent;
  border-color: #b771b0
}

.btn-outline-purple:hover {
  color: #fff;
  background-color: #b771b0;
  border-color: #b771b0
}

.btn-outline-purple.focus,
.btn-outline-purple:focus {
  box-shadow: 0 0 0 2px rgba(183, 113, 176, .5)
}

.btn-outline-purple.disabled,
.btn-outline-purple:disabled {
  color: #b771b0;
  background-color: transparent
}

.btn-outline-purple.active,
.btn-outline-purple:active,
.show>.btn-outline-purple.dropdown-toggle {
  color: #fff;
  background-color: #b771b0;
  border-color: #b771b0
}

.btn-purple.btn-invert {
  background-color: #a3529b
}

.btn-purple.btn-invert:focus,
.btn-purple.btn-invert:hover {
  background-color: #b771b0
}

.btn-pink {
  color: #fff;
  background-color: #CC164D;
  border-color: #CC164D
}

.btn-pink.focus,
.btn-pink:focus {
  color: #fff;
  background-color: #9e113c;
  border-color: #590a22
}

.btn-pink:hover {
  color: #fff;
  background-color: #9e113c;
  border-color: #951038
}

.btn-pink.active,
.btn-pink:active,
.open>.btn-pink.dropdown-toggle,
.show>.btn-pink.dropdown-toggle {
  color: #fff;
  background-color: #9e113c;
  border-color: #951038;
  background-image: none
}

.btn-pink.active.focus,
.btn-pink.active:focus,
.btn-pink.active:hover,
.btn-pink:active.focus,
.btn-pink:active:focus,
.btn-pink:active:hover,
.open>.btn-pink.dropdown-toggle.focus,
.open>.btn-pink.dropdown-toggle:focus,
.open>.btn-pink.dropdown-toggle:hover,
.show>.btn-pink.dropdown-toggle.focus,
.show>.btn-pink.dropdown-toggle:focus,
.show>.btn-pink.dropdown-toggle:hover {
  color: #fff;
  background-color: #7e0e2f;
  border-color: #590a22
}

.btn-pink.disabled,
.btn-pink.disabled.focus,
.btn-pink.disabled:focus,
.btn-pink.disabled:hover,
.btn-pink:disabled,
.btn-pink:disabled.focus,
.btn-pink:disabled:focus,
.btn-pink:disabled:hover,
.btn-pink[disabled],
.btn-pink[disabled].focus,
.btn-pink[disabled]:focus,
.btn-pink[disabled]:hover,
fieldset[disabled] .btn-pink,
fieldset[disabled] .btn-pink.focus,
fieldset[disabled] .btn-pink:focus,
fieldset[disabled] .btn-pink:hover {
  background-color: #CC164D;
  border-color: #CC164D
}

.btn-pink .badge {
  color: #CC164D;
  background-color: #fff
}

.btn-outline-pink {
  color: #CC164D;
  background-image: none;
  background-color: transparent;
  border-color: #CC164D
}

.btn-outline-pink:hover {
  color: #fff;
  background-color: #CC164D;
  border-color: #CC164D
}

.btn-outline-pink.focus,
.btn-outline-pink:focus {
  box-shadow: 0 0 0 2px rgba(204, 22, 77, .5)
}

.btn-outline-pink.disabled,
.btn-outline-pink:disabled {
  color: #CC164D;
  background-color: transparent
}

.btn-outline-pink.active,
.btn-outline-pink:active,
.show>.btn-outline-pink.dropdown-toggle {
  color: #fff;
  background-color: #CC164D;
  border-color: #CC164D
}

.btn-pink.btn-invert {
  background-color: #9e113c
}

.btn-pink.btn-invert:focus,
.btn-pink.btn-invert:hover {
  background-color: #CC164D
}

.btn-orange {
  color: #fff;
  background-color: #e67e22;
  border-color: #e67e22
}

.btn-orange.focus,
.btn-orange:focus {
  color: #fff;
  background-color: #bf6516;
  border-color: #7b410e
}

.btn-orange:hover {
  color: #fff;
  background-color: #bf6516;
  border-color: #b66015
}

.btn-orange.active,
.btn-orange:active,
.open>.btn-orange.dropdown-toggle,
.show>.btn-orange.dropdown-toggle {
  color: #fff;
  background-color: #bf6516;
  border-color: #b66015;
  background-image: none
}

.btn-orange.active.focus,
.btn-orange.active:focus,
.btn-orange.active:hover,
.btn-orange:active.focus,
.btn-orange:active:focus,
.btn-orange:active:hover,
.open>.btn-orange.dropdown-toggle.focus,
.open>.btn-orange.dropdown-toggle:focus,
.open>.btn-orange.dropdown-toggle:hover,
.show>.btn-orange.dropdown-toggle.focus,
.show>.btn-orange.dropdown-toggle:focus,
.show>.btn-orange.dropdown-toggle:hover {
  color: #fff;
  background-color: #9f5412;
  border-color: #7b410e
}

.btn-orange.disabled,
.btn-orange.disabled.focus,
.btn-orange.disabled:focus,
.btn-orange.disabled:hover,
.btn-orange:disabled,
.btn-orange:disabled.focus,
.btn-orange:disabled:focus,
.btn-orange:disabled:hover,
.btn-orange[disabled],
.btn-orange[disabled].focus,
.btn-orange[disabled]:focus,
.btn-orange[disabled]:hover,
fieldset[disabled] .btn-orange,
fieldset[disabled] .btn-orange.focus,
fieldset[disabled] .btn-orange:focus,
fieldset[disabled] .btn-orange:hover {
  background-color: #e67e22;
  border-color: #e67e22
}

.btn-orange .badge {
  color: #e67e22;
  background-color: #fff
}

.btn-outline-orange {
  color: #e67e22;
  background-image: none;
  background-color: transparent;
  border-color: #e67e22
}

.btn-outline-orange:hover {
  color: #fff;
  background-color: #e67e22;
  border-color: #e67e22
}

.btn-outline-orange.focus,
.btn-outline-orange:focus {
  box-shadow: 0 0 0 2px rgba(230, 126, 34, .5)
}

.btn-outline-orange.disabled,
.btn-outline-orange:disabled {
  color: #e67e22;
  background-color: transparent
}

.btn-outline-orange.active,
.btn-outline-orange:active,
.show>.btn-outline-orange.dropdown-toggle {
  color: #fff;
  background-color: #e67e22;
  border-color: #e67e22
}

.btn-orange.btn-invert {
  background-color: #bf6516
}

.btn-orange.btn-invert:focus,
.btn-orange.btn-invert:hover {
  background-color: #e67e22
}

.btn-lime {
  color: #fff;
  background-color: #b1dc44;
  border-color: #b1dc44
}

.btn-lime.focus,
.btn-lime:focus {
  color: #fff;
  background-color: #9ac825;
  border-color: #688719
}

.btn-lime:hover {
  color: #fff;
  background-color: #9ac825;
  border-color: #93bf24
}

.btn-lime.active,
.btn-lime:active,
.open>.btn-lime.dropdown-toggle,
.show>.btn-lime.dropdown-toggle {
  color: #fff;
  background-color: #9ac825;
  border-color: #93bf24;
  background-image: none
}

.btn-lime.active.focus,
.btn-lime.active:focus,
.btn-lime.active:hover,
.btn-lime:active.focus,
.btn-lime:active:focus,
.btn-lime:active:hover,
.open>.btn-lime.dropdown-toggle.focus,
.open>.btn-lime.dropdown-toggle:focus,
.open>.btn-lime.dropdown-toggle:hover,
.show>.btn-lime.dropdown-toggle.focus,
.show>.btn-lime.dropdown-toggle:focus,
.show>.btn-lime.dropdown-toggle:hover {
  color: #fff;
  background-color: #83aa20;
  border-color: #688719
}

.btn-lime.disabled,
.btn-lime.disabled.focus,
.btn-lime.disabled:focus,
.btn-lime.disabled:hover,
.btn-lime:disabled,
.btn-lime:disabled.focus,
.btn-lime:disabled:focus,
.btn-lime:disabled:hover,
.btn-lime[disabled],
.btn-lime[disabled].focus,
.btn-lime[disabled]:focus,
.btn-lime[disabled]:hover,
fieldset[disabled] .btn-lime,
fieldset[disabled] .btn-lime.focus,
fieldset[disabled] .btn-lime:focus,
fieldset[disabled] .btn-lime:hover {
  background-color: #b1dc44;
  border-color: #b1dc44
}

.btn-lime .badge {
  color: #b1dc44;
  background-color: #fff
}

.btn-outline-lime {
  color: #b1dc44;
  background-image: none;
  background-color: transparent;
  border-color: #b1dc44
}

.btn-outline-lime:hover {
  color: #fff;
  background-color: #b1dc44;
  border-color: #b1dc44
}

.btn-outline-lime.focus,
.btn-outline-lime:focus {
  box-shadow: 0 0 0 2px rgba(177, 220, 68, .5)
}

.btn-outline-lime.disabled,
.btn-outline-lime:disabled {
  color: #b1dc44;
  background-color: transparent
}

.btn-outline-lime.active,
.btn-outline-lime:active,
.show>.btn-outline-lime.dropdown-toggle {
  color: #fff;
  background-color: #b1dc44;
  border-color: #b1dc44
}

.btn-lime.btn-invert {
  background-color: #9ac825
}

.btn-lime.btn-invert:focus,
.btn-lime.btn-invert:hover {
  background-color: #b1dc44
}

.btn-blue-dark {
  color: #fff;
  background-color: #34495e;
  border-color: #34495e
}

.btn-blue-dark.focus,
.btn-blue-dark:focus {
  color: #fff;
  background-color: #22303d;
  border-color: #07090c
}

.btn-blue-dark:hover {
  color: #fff;
  background-color: #22303d;
  border-color: #1e2a37
}

.btn-blue-dark.active,
.btn-blue-dark:active,
.open>.btn-blue-dark.dropdown-toggle,
.show>.btn-blue-dark.dropdown-toggle {
  color: #fff;
  background-color: #22303d;
  border-color: #1e2a37;
  background-image: none
}

.btn-blue-dark.active.focus,
.btn-blue-dark.active:focus,
.btn-blue-dark.active:hover,
.btn-blue-dark:active.focus,
.btn-blue-dark:active:focus,
.btn-blue-dark:active:hover,
.open>.btn-blue-dark.dropdown-toggle.focus,
.open>.btn-blue-dark.dropdown-toggle:focus,
.open>.btn-blue-dark.dropdown-toggle:hover,
.show>.btn-blue-dark.dropdown-toggle.focus,
.show>.btn-blue-dark.dropdown-toggle:focus,
.show>.btn-blue-dark.dropdown-toggle:hover {
  color: #fff;
  background-color: #151e26;
  border-color: #07090c
}

.btn-blue-dark.disabled,
.btn-blue-dark.disabled.focus,
.btn-blue-dark.disabled:focus,
.btn-blue-dark.disabled:hover,
.btn-blue-dark:disabled,
.btn-blue-dark:disabled.focus,
.btn-blue-dark:disabled:focus,
.btn-blue-dark:disabled:hover,
.btn-blue-dark[disabled],
.btn-blue-dark[disabled].focus,
.btn-blue-dark[disabled]:focus,
.btn-blue-dark[disabled]:hover,
fieldset[disabled] .btn-blue-dark,
fieldset[disabled] .btn-blue-dark.focus,
fieldset[disabled] .btn-blue-dark:focus,
fieldset[disabled] .btn-blue-dark:hover {
  background-color: #34495e;
  border-color: #34495e
}

.btn-blue-dark .badge {
  color: #34495e;
  background-color: #fff
}

.btn-outline-blue-dark {
  color: #34495e;
  background-image: none;
  background-color: transparent;
  border-color: #34495e
}

.btn-outline-blue-dark:hover {
  color: #fff;
  background-color: #34495e;
  border-color: #34495e
}

.btn-outline-blue-dark.focus,
.btn-outline-blue-dark:focus {
  box-shadow: 0 0 0 2px rgba(52, 73, 94, .5)
}

.btn-outline-blue-dark.disabled,
.btn-outline-blue-dark:disabled {
  color: #34495e;
  background-color: transparent
}

.btn-outline-blue-dark.active,
.btn-outline-blue-dark:active,
.show>.btn-outline-blue-dark.dropdown-toggle {
  color: #fff;
  background-color: #34495e;
  border-color: #34495e
}

.btn-blue-dark.btn-invert {
  background-color: #22303d
}

.btn-blue-dark.btn-invert:focus,
.btn-blue-dark.btn-invert:hover {
  background-color: #34495e
}

.btn-red-dark {
  color: #fff;
  background-color: #a10f2b;
  border-color: #a10f2b
}

.btn-red-dark.focus,
.btn-red-dark:focus {
  color: #fff;
  background-color: #720b1f;
  border-color: #2c040c
}

.btn-red-dark:hover {
  color: #fff;
  background-color: #720b1f;
  border-color: #690a1c
}

.btn-red-dark.active,
.btn-red-dark:active,
.open>.btn-red-dark.dropdown-toggle,
.show>.btn-red-dark.dropdown-toggle {
  color: #fff;
  background-color: #720b1f;
  border-color: #690a1c;
  background-image: none
}

.btn-red-dark.active.focus,
.btn-red-dark.active:focus,
.btn-red-dark.active:hover,
.btn-red-dark:active.focus,
.btn-red-dark:active:focus,
.btn-red-dark:active:hover,
.open>.btn-red-dark.dropdown-toggle.focus,
.open>.btn-red-dark.dropdown-toggle:focus,
.open>.btn-red-dark.dropdown-toggle:hover,
.show>.btn-red-dark.dropdown-toggle.focus,
.show>.btn-red-dark.dropdown-toggle:focus,
.show>.btn-red-dark.dropdown-toggle:hover {
  color: #fff;
  background-color: #520816;
  border-color: #2c040c
}

.btn-red-dark.disabled,
.btn-red-dark.disabled.focus,
.btn-red-dark.disabled:focus,
.btn-red-dark.disabled:hover,
.btn-red-dark:disabled,
.btn-red-dark:disabled.focus,
.btn-red-dark:disabled:focus,
.btn-red-dark:disabled:hover,
.btn-red-dark[disabled],
.btn-red-dark[disabled].focus,
.btn-red-dark[disabled]:focus,
.btn-red-dark[disabled]:hover,
fieldset[disabled] .btn-red-dark,
fieldset[disabled] .btn-red-dark.focus,
fieldset[disabled] .btn-red-dark:focus,
fieldset[disabled] .btn-red-dark:hover {
  background-color: #a10f2b;
  border-color: #a10f2b
}

.btn-red-dark .badge {
  color: #a10f2b;
  background-color: #fff
}

.btn-outline-red-dark {
  color: #a10f2b;
  background-image: none;
  background-color: transparent;
  border-color: #a10f2b
}

.btn-outline-red-dark:hover {
  color: #fff;
  background-color: #a10f2b;
  border-color: #a10f2b
}

.btn-outline-red-dark.focus,
.btn-outline-red-dark:focus {
  box-shadow: 0 0 0 2px rgba(161, 15, 43, .5)
}

.btn-outline-red-dark.disabled,
.btn-outline-red-dark:disabled {
  color: #a10f2b;
  background-color: transparent
}

.btn-outline-red-dark.active,
.btn-outline-red-dark:active,
.show>.btn-outline-red-dark.dropdown-toggle {
  color: #fff;
  background-color: #a10f2b;
  border-color: #a10f2b
}

.btn-red-dark.btn-invert {
  background-color: #720b1f
}

.btn-red-dark.btn-invert:focus,
.btn-red-dark.btn-invert:hover {
  background-color: #a10f2b
}

.btn-brown {
  color: #fff;
  background-color: #91633c;
  border-color: #91633c
}

.btn-brown.focus,
.btn-brown:focus {
  color: #fff;
  background-color: #6d4a2d;
  border-color: #372517
}

.btn-brown:hover {
  color: #fff;
  background-color: #6d4a2d;
  border-color: #66452a
}

.btn-brown.active,
.btn-brown:active,
.open>.btn-brown.dropdown-toggle,
.show>.btn-brown.dropdown-toggle {
  color: #fff;
  background-color: #6d4a2d;
  border-color: #66452a;
  background-image: none
}

.btn-brown.active.focus,
.btn-brown.active:focus,
.btn-brown.active:hover,
.btn-brown:active.focus,
.btn-brown:active:focus,
.btn-brown:active:hover,
.open>.btn-brown.dropdown-toggle.focus,
.open>.btn-brown.dropdown-toggle:focus,
.open>.btn-brown.dropdown-toggle:hover,
.show>.btn-brown.dropdown-toggle.focus,
.show>.btn-brown.dropdown-toggle:focus,
.show>.btn-brown.dropdown-toggle:hover {
  color: #fff;
  background-color: #543923;
  border-color: #372517
}

.btn-brown.disabled,
.btn-brown.disabled.focus,
.btn-brown.disabled:focus,
.btn-brown.disabled:hover,
.btn-brown:disabled,
.btn-brown:disabled.focus,
.btn-brown:disabled:focus,
.btn-brown:disabled:hover,
.btn-brown[disabled],
.btn-brown[disabled].focus,
.btn-brown[disabled]:focus,
.btn-brown[disabled]:hover,
fieldset[disabled] .btn-brown,
fieldset[disabled] .btn-brown.focus,
fieldset[disabled] .btn-brown:focus,
fieldset[disabled] .btn-brown:hover {
  background-color: #91633c;
  border-color: #91633c
}

.btn-brown .badge {
  color: #91633c;
  background-color: #fff
}

.btn-outline-brown {
  color: #91633c;
  background-image: none;
  background-color: transparent;
  border-color: #91633c
}

.btn-outline-brown:hover {
  color: #fff;
  background-color: #91633c;
  border-color: #91633c
}

.btn-outline-brown.focus,
.btn-outline-brown:focus {
  box-shadow: 0 0 0 2px rgba(145, 99, 60, .5)
}

.btn-outline-brown.disabled,
.btn-outline-brown:disabled {
  color: #91633c;
  background-color: transparent
}

.btn-outline-brown.active,
.btn-outline-brown:active,
.show>.btn-outline-brown.dropdown-toggle {
  color: #fff;
  background-color: #91633c;
  border-color: #91633c
}

.btn-brown.btn-invert {
  background-color: #6d4a2d
}

.btn-brown.btn-invert:focus,
.btn-brown.btn-invert:hover {
  background-color: #91633c
}

.btn-cyan-dark {
  color: #fff;
  background-color: #008b8b;
  border-color: #008b8b
}

.btn-cyan-dark.focus,
.btn-cyan-dark:focus {
  color: #fff;
  background-color: #005858;
  border-color: #000c0c
}

.btn-cyan-dark:hover {
  color: #fff;
  background-color: #005858;
  border-color: #004e4e
}

.btn-cyan-dark.active,
.btn-cyan-dark:active,
.open>.btn-cyan-dark.dropdown-toggle,
.show>.btn-cyan-dark.dropdown-toggle {
  color: #fff;
  background-color: #005858;
  border-color: #004e4e;
  background-image: none
}

.btn-cyan-dark.active.focus,
.btn-cyan-dark.active:focus,
.btn-cyan-dark.active:hover,
.btn-cyan-dark:active.focus,
.btn-cyan-dark:active:focus,
.btn-cyan-dark:active:hover,
.open>.btn-cyan-dark.dropdown-toggle.focus,
.open>.btn-cyan-dark.dropdown-toggle:focus,
.open>.btn-cyan-dark.dropdown-toggle:hover,
.show>.btn-cyan-dark.dropdown-toggle.focus,
.show>.btn-cyan-dark.dropdown-toggle:focus,
.show>.btn-cyan-dark.dropdown-toggle:hover {
  color: #fff;
  background-color: #003434;
  border-color: #000c0c
}

.btn-cyan-dark.disabled,
.btn-cyan-dark.disabled.focus,
.btn-cyan-dark.disabled:focus,
.btn-cyan-dark.disabled:hover,
.btn-cyan-dark:disabled,
.btn-cyan-dark:disabled.focus,
.btn-cyan-dark:disabled:focus,
.btn-cyan-dark:disabled:hover,
.btn-cyan-dark[disabled],
.btn-cyan-dark[disabled].focus,
.btn-cyan-dark[disabled]:focus,
.btn-cyan-dark[disabled]:hover,
fieldset[disabled] .btn-cyan-dark,
fieldset[disabled] .btn-cyan-dark.focus,
fieldset[disabled] .btn-cyan-dark:focus,
fieldset[disabled] .btn-cyan-dark:hover {
  background-color: #008b8b;
  border-color: #008b8b
}

.btn-cyan-dark .badge {
  color: #008b8b;
  background-color: #fff
}

.btn-outline-cyan-dark {
  color: #008b8b;
  background-image: none;
  background-color: transparent;
  border-color: #008b8b
}

.btn-outline-cyan-dark:hover {
  color: #fff;
  background-color: #008b8b;
  border-color: #008b8b
}

.btn-outline-cyan-dark.focus,
.btn-outline-cyan-dark:focus {
  box-shadow: 0 0 0 2px rgba(0, 139, 139, .5)
}

.btn-outline-cyan-dark.disabled,
.btn-outline-cyan-dark:disabled {
  color: #008b8b;
  background-color: transparent
}

.btn-outline-cyan-dark.active,
.btn-outline-cyan-dark:active,
.show>.btn-outline-cyan-dark.dropdown-toggle {
  color: #fff;
  background-color: #008b8b;
  border-color: #008b8b
}

.btn-cyan-dark.btn-invert {
  background-color: #005858
}

.btn-cyan-dark.btn-invert:focus,
.btn-cyan-dark.btn-invert:hover {
  background-color: #008b8b
}

.btn-yellow {
  color: #fff;
  background-color: #D4AC0D;
  border-color: #D4AC0D
}

.btn-yellow.focus,
.btn-yellow:focus {
  color: #fff;
  background-color: #a4850a;
  border-color: #5c4b06
}

.btn-yellow:hover {
  color: #fff;
  background-color: #a4850a;
  border-color: #9a7d09
}

.btn-yellow.active,
.btn-yellow:active,
.open>.btn-yellow.dropdown-toggle,
.show>.btn-yellow.dropdown-toggle {
  color: #fff;
  background-color: #a4850a;
  border-color: #9a7d09;
  background-image: none
}

.btn-yellow.active.focus,
.btn-yellow.active:focus,
.btn-yellow.active:hover,
.btn-yellow:active.focus,
.btn-yellow:active:focus,
.btn-yellow:active:hover,
.open>.btn-yellow.dropdown-toggle.focus,
.open>.btn-yellow.dropdown-toggle:focus,
.open>.btn-yellow.dropdown-toggle:hover,
.show>.btn-yellow.dropdown-toggle.focus,
.show>.btn-yellow.dropdown-toggle:focus,
.show>.btn-yellow.dropdown-toggle:hover {
  color: #fff;
  background-color: #826a08;
  border-color: #5c4b06
}

.btn-yellow.disabled,
.btn-yellow.disabled.focus,
.btn-yellow.disabled:focus,
.btn-yellow.disabled:hover,
.btn-yellow:disabled,
.btn-yellow:disabled.focus,
.btn-yellow:disabled:focus,
.btn-yellow:disabled:hover,
.btn-yellow[disabled],
.btn-yellow[disabled].focus,
.btn-yellow[disabled]:focus,
.btn-yellow[disabled]:hover,
fieldset[disabled] .btn-yellow,
fieldset[disabled] .btn-yellow.focus,
fieldset[disabled] .btn-yellow:focus,
fieldset[disabled] .btn-yellow:hover {
  background-color: #D4AC0D;
  border-color: #D4AC0D
}

.btn-yellow .badge {
  color: #D4AC0D;
  background-color: #fff
}

.btn-outline-yellow {
  color: #D4AC0D;
  background-image: none;
  background-color: transparent;
  border-color: #D4AC0D
}

.btn-outline-yellow:hover {
  color: #fff;
  background-color: #D4AC0D;
  border-color: #D4AC0D
}

.btn-outline-yellow.focus,
.btn-outline-yellow:focus {
  box-shadow: 0 0 0 2px rgba(212, 172, 13, .5)
}

.btn-outline-yellow.disabled,
.btn-outline-yellow:disabled {
  color: #D4AC0D;
  background-color: transparent
}

.btn-outline-yellow.active,
.btn-outline-yellow:active,
.show>.btn-outline-yellow.dropdown-toggle {
  color: #fff;
  background-color: #D4AC0D;
  border-color: #D4AC0D
}

.btn-yellow.btn-invert {
  background-color: #a4850a
}

.btn-yellow.btn-invert:focus,
.btn-yellow.btn-invert:hover {
  background-color: #D4AC0D
}

.btn-slate {
  color: #fff;
  background-color: #5D6D7E;
  border-color: #5D6D7E
}

.btn-slate.focus,
.btn-slate:focus {
  color: #fff;
  background-color: #475461;
  border-color: #272e35
}

.btn-slate:hover {
  color: #fff;
  background-color: #475461;
  border-color: #434f5b
}

.btn-slate.active,
.btn-slate:active,
.open>.btn-slate.dropdown-toggle,
.show>.btn-slate.dropdown-toggle {
  color: #fff;
  background-color: #475461;
  border-color: #434f5b;
  background-image: none
}

.btn-slate.active.focus,
.btn-slate.active:focus,
.btn-slate.active:hover,
.btn-slate:active.focus,
.btn-slate:active:focus,
.btn-slate:active:hover,
.open>.btn-slate.dropdown-toggle.focus,
.open>.btn-slate.dropdown-toggle:focus,
.open>.btn-slate.dropdown-toggle:hover,
.show>.btn-slate.dropdown-toggle.focus,
.show>.btn-slate.dropdown-toggle:focus,
.show>.btn-slate.dropdown-toggle:hover {
  color: #fff;
  background-color: #38424c;
  border-color: #272e35
}

.btn-slate.disabled,
.btn-slate.disabled.focus,
.btn-slate.disabled:focus,
.btn-slate.disabled:hover,
.btn-slate:disabled,
.btn-slate:disabled.focus,
.btn-slate:disabled:focus,
.btn-slate:disabled:hover,
.btn-slate[disabled],
.btn-slate[disabled].focus,
.btn-slate[disabled]:focus,
.btn-slate[disabled]:hover,
fieldset[disabled] .btn-slate,
fieldset[disabled] .btn-slate.focus,
fieldset[disabled] .btn-slate:focus,
fieldset[disabled] .btn-slate:hover {
  background-color: #5D6D7E;
  border-color: #5D6D7E
}

.btn-slate .badge {
  color: #5D6D7E;
  background-color: #fff
}

.btn-outline-slate {
  color: #5D6D7E;
  background-image: none;
  background-color: transparent;
  border-color: #5D6D7E
}

.btn-outline-slate:hover {
  color: #fff;
  background-color: #5D6D7E;
  border-color: #5D6D7E
}

.btn-outline-slate.focus,
.btn-outline-slate:focus {
  box-shadow: 0 0 0 2px rgba(93, 109, 126, .5)
}

.btn-outline-slate.disabled,
.btn-outline-slate:disabled {
  color: #5D6D7E;
  background-color: transparent
}

.btn-outline-slate.active,
.btn-outline-slate:active,
.show>.btn-outline-slate.dropdown-toggle {
  color: #fff;
  background-color: #5D6D7E;
  border-color: #5D6D7E
}

.btn-slate.btn-invert {
  background-color: #475461
}

.btn-slate.btn-invert:focus,
.btn-slate.btn-invert:hover {
  background-color: #5D6D7E
}

.btn-olive {
  color: #fff;
  background-color: olive;
  border-color: olive
}

.btn-olive.focus,
.btn-olive:focus {
  color: #fff;
  background-color: #4d4d00;
  border-color: #010100
}

.btn-olive:hover {
  color: #fff;
  background-color: #4d4d00;
  border-color: #434300
}

.btn-olive.active,
.btn-olive:active,
.open>.btn-olive.dropdown-toggle,
.show>.btn-olive.dropdown-toggle {
  color: #fff;
  background-color: #4d4d00;
  border-color: #434300;
  background-image: none
}

.btn-olive.active.focus,
.btn-olive.active:focus,
.btn-olive.active:hover,
.btn-olive:active.focus,
.btn-olive:active:focus,
.btn-olive:active:hover,
.open>.btn-olive.dropdown-toggle.focus,
.open>.btn-olive.dropdown-toggle:focus,
.open>.btn-olive.dropdown-toggle:hover,
.show>.btn-olive.dropdown-toggle.focus,
.show>.btn-olive.dropdown-toggle:focus,
.show>.btn-olive.dropdown-toggle:hover {
  color: #fff;
  background-color: #292900;
  border-color: #010100
}

.btn-olive.disabled,
.btn-olive.disabled.focus,
.btn-olive.disabled:focus,
.btn-olive.disabled:hover,
.btn-olive:disabled,
.btn-olive:disabled.focus,
.btn-olive:disabled:focus,
.btn-olive:disabled:hover,
.btn-olive[disabled],
.btn-olive[disabled].focus,
.btn-olive[disabled]:focus,
.btn-olive[disabled]:hover,
fieldset[disabled] .btn-olive,
fieldset[disabled] .btn-olive.focus,
fieldset[disabled] .btn-olive:focus,
fieldset[disabled] .btn-olive:hover {
  background-color: olive;
  border-color: olive
}

.btn-olive .badge {
  color: olive;
  background-color: #fff
}

.btn-outline-olive {
  color: olive;
  background-image: none;
  background-color: transparent;
  border-color: olive
}

.btn-outline-olive:hover {
  color: #fff;
  background-color: olive;
  border-color: olive
}

.btn-outline-olive.focus,
.btn-outline-olive:focus {
  box-shadow: 0 0 0 2px rgba(128, 128, 0, .5)
}

.btn-outline-olive.disabled,
.btn-outline-olive:disabled {
  color: olive;
  background-color: transparent
}

.btn-outline-olive.active,
.btn-outline-olive:active,
.show>.btn-outline-olive.dropdown-toggle {
  color: #fff;
  background-color: olive;
  border-color: olive
}

.btn-olive.btn-invert {
  background-color: #4d4d00
}

.btn-olive.btn-invert:focus,
.btn-olive.btn-invert:hover {
  background-color: olive
}

.btn-teal {
  color: #fff;
  background-color: teal;
  border-color: teal
}

.btn-teal.focus,
.btn-teal:focus {
  color: #fff;
  background-color: #004d4d;
  border-color: #000101
}

.btn-teal:hover {
  color: #fff;
  background-color: #004d4d;
  border-color: #004343
}

.btn-teal.active,
.btn-teal:active,
.open>.btn-teal.dropdown-toggle,
.show>.btn-teal.dropdown-toggle {
  color: #fff;
  background-color: #004d4d;
  border-color: #004343;
  background-image: none
}

.btn-teal.active.focus,
.btn-teal.active:focus,
.btn-teal.active:hover,
.btn-teal:active.focus,
.btn-teal:active:focus,
.btn-teal:active:hover,
.open>.btn-teal.dropdown-toggle.focus,
.open>.btn-teal.dropdown-toggle:focus,
.open>.btn-teal.dropdown-toggle:hover,
.show>.btn-teal.dropdown-toggle.focus,
.show>.btn-teal.dropdown-toggle:focus,
.show>.btn-teal.dropdown-toggle:hover {
  color: #fff;
  background-color: #002929;
  border-color: #000101
}

.btn-teal.disabled,
.btn-teal.disabled.focus,
.btn-teal.disabled:focus,
.btn-teal.disabled:hover,
.btn-teal:disabled,
.btn-teal:disabled.focus,
.btn-teal:disabled:focus,
.btn-teal:disabled:hover,
.btn-teal[disabled],
.btn-teal[disabled].focus,
.btn-teal[disabled]:focus,
.btn-teal[disabled]:hover,
fieldset[disabled] .btn-teal,
fieldset[disabled] .btn-teal.focus,
fieldset[disabled] .btn-teal:focus,
fieldset[disabled] .btn-teal:hover {
  background-color: teal;
  border-color: teal
}

.btn-teal .badge {
  color: teal;
  background-color: #fff
}

.btn-outline-teal {
  color: teal;
  background-image: none;
  background-color: transparent;
  border-color: teal
}

.btn-outline-teal:hover {
  color: #fff;
  background-color: teal;
  border-color: teal
}

.btn-outline-teal.focus,
.btn-outline-teal:focus {
  box-shadow: 0 0 0 2px rgba(0, 128, 128, .5)
}

.btn-outline-teal.disabled,
.btn-outline-teal:disabled {
  color: teal;
  background-color: transparent
}

.btn-outline-teal.active,
.btn-outline-teal:active,
.show>.btn-outline-teal.dropdown-toggle {
  color: #fff;
  background-color: teal;
  border-color: teal
}

.btn-teal.btn-invert {
  background-color: #004d4d
}

.btn-teal.btn-invert:focus,
.btn-teal.btn-invert:hover {
  background-color: teal
}

.btn-green-bright {
  color: #fff;
  background-color: #2ECC71;
  border-color: #2ECC71
}

.btn-green-bright.focus,
.btn-green-bright:focus {
  color: #fff;
  background-color: #25a25a;
  border-color: #176437
}

.btn-green-bright:hover {
  color: #fff;
  background-color: #25a25a;
  border-color: #239a55
}

.btn-green-bright.active,
.btn-green-bright:active,
.open>.btn-green-bright.dropdown-toggle,
.show>.btn-green-bright.dropdown-toggle {
  color: #fff;
  background-color: #25a25a;
  border-color: #239a55;
  background-image: none
}

.btn-green-bright.active.focus,
.btn-green-bright.active:focus,
.btn-green-bright.active:hover,
.btn-green-bright:active.focus,
.btn-green-bright:active:focus,
.btn-green-bright:active:hover,
.open>.btn-green-bright.dropdown-toggle.focus,
.open>.btn-green-bright.dropdown-toggle:focus,
.open>.btn-green-bright.dropdown-toggle:hover,
.show>.btn-green-bright.dropdown-toggle.focus,
.show>.btn-green-bright.dropdown-toggle:focus,
.show>.btn-green-bright.dropdown-toggle:hover {
  color: #fff;
  background-color: #1e854a;
  border-color: #176437
}

.btn-green-bright.disabled,
.btn-green-bright.disabled.focus,
.btn-green-bright.disabled:focus,
.btn-green-bright.disabled:hover,
.btn-green-bright:disabled,
.btn-green-bright:disabled.focus,
.btn-green-bright:disabled:focus,
.btn-green-bright:disabled:hover,
.btn-green-bright[disabled],
.btn-green-bright[disabled].focus,
.btn-green-bright[disabled]:focus,
.btn-green-bright[disabled]:hover,
fieldset[disabled] .btn-green-bright,
fieldset[disabled] .btn-green-bright.focus,
fieldset[disabled] .btn-green-bright:focus,
fieldset[disabled] .btn-green-bright:hover {
  background-color: #2ECC71;
  border-color: #2ECC71
}

.btn-green-bright .badge {
  color: #2ECC71;
  background-color: #fff
}

.btn-outline-green-bright {
  color: #2ECC71;
  background-image: none;
  background-color: transparent;
  border-color: #2ECC71
}

.btn-outline-green-bright:hover {
  color: #fff;
  background-color: #2ECC71;
  border-color: #2ECC71
}

.btn-outline-green-bright.focus,
.btn-outline-green-bright:focus {
  box-shadow: 0 0 0 2px rgba(46, 204, 113, .5)
}

.btn-outline-green-bright.disabled,
.btn-outline-green-bright:disabled {
  color: #2ECC71;
  background-color: transparent
}

.btn-outline-green-bright.active,
.btn-outline-green-bright:active,
.show>.btn-outline-green-bright.dropdown-toggle {
  color: #fff;
  background-color: #2ECC71;
  border-color: #2ECC71
}

.btn-green-bright.btn-invert {
  background-color: #25a25a
}

.btn-green-bright.btn-invert:focus,
.btn-green-bright.btn-invert:hover {
  background-color: #2ECC71
}

.nav-pills .nav-link {
  border-radius: 3px
}

.breadcrumb {
  border-radius: 2px;
  font-size: 90%
}

.page-item:first-child .page-link,
.pagination-lg .page-item:first-child .page-link,
.pagination-sm .page-item:first-child .page-link {
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px
}

.page-item:last-child .page-link,
.pagination-lg .page-item:last-child .page-link,
.pagination-sm .page-item:last-child .page-link {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px
}

.nav-icons .nav-link {
  padding: 0 3px 0 0
}

.nav-sm .nav-link {
  font-size: 85%;
  padding: .35em .5em
}

.nav-lg .nav-link {
  font-size: 120%;
  padding: .75em 1.5em
}

.nav-tabs {
  margin-bottom: 1em
}

.nav-tabs.card-header-tabs {
  margin-bottom: -.75rem
}

.nav-tabs .nav-item,
.nav-tabs .nav-item.show {
  position: relative
}

.nav-tabs .nav-item+.nav-item,
.nav-tabs .nav-item.show+.nav-item {
  margin-left: 0
}

.nav-tabs .nav-item.show:last-child,
.nav-tabs .nav-item:last-child {
  margin-bottom: 0;
  border-bottom: none
}

.nav-tabs .nav-link,
.nav-tabs .nav-link.disabled,
.nav-tabs .nav-link:active,
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-radius: 2px!important;
  margin-right: 5px;
  margin-bottom: -1px;
  background: 0 0;
  border: 1px solid #E6E6E6
}

.nav-tabs .nav-link:hover {
  background: #f7f7f7
}

.nav-tabs .active>.nav-link,
.nav-tabs .active>.nav-link:focus,
.nav-tabs .active>.nav-link:hover,
.nav-tabs .nav-link.active,
.nav-tabs .nav-link.active:focus,
.nav-tabs .nav-link.active:hover {
  color: #55A79A;
  border: 1px solid #E6E6E6!important;
  border-bottom-color: #fff!important;
  position: relative;
  background: #fff
}

.nav-tabs .active>.nav-link:before,
.nav-tabs .active>.nav-link:focus:before,
.nav-tabs .active>.nav-link:hover:before,
.nav-tabs .nav-link.active:before,
.nav-tabs .nav-link.active:focus:before,
.nav-tabs .nav-link.active:hover:before {
  height: 4px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  content: ''
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-item.show .nav-link:active,
.nav-tabs .nav-item.show .nav-link:focus,
.nav-tabs .nav-item.show .nav-link:hover {
  background: #f7f7f7
}

.nav-tabs .nav-item.show .nav-link.active,
.nav-tabs .nav-item.show .nav-link.active:active,
.nav-tabs .nav-item.show .nav-link.active:focus,
.nav-tabs .nav-item.show .nav-link.active:hover {
  background: #fff
}

.nav-stacked {
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column
}

.nav-overlay,
.nav-section-menu .nav,
.nav-section-menu.nav {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column
}

.nav-stacked .nav-item {
  margin: 0!important;
  float: none!important;
  display: block!important
}

.nav-stacked .nav-link {
  display: block
}

.nav-tabs.nav-stacked {
  margin-right: 0;
  border-bottom: 0;
  border-right: none
}

@media (min-width:576px) {
  .nav-tabs.nav-stacked {
    border-right: 1px solid #E6E6E6;
    padding-right: 0
  }
}

.nav-tabs.nav-stacked .nav-link,
.nav-tabs.nav-stacked .nav-link.disabled,
.nav-tabs.nav-stacked .nav-link:active,
.nav-tabs.nav-stacked .nav-link:focus,
.nav-tabs.nav-stacked .nav-link:hover {
  border-radius: 0;
  position: relative;
  border-color: transparent;
  margin-right: 0
}

.nav-tabs.nav-stacked .nav-link i,
.nav-tabs.nav-stacked .nav-link.disabled i,
.nav-tabs.nav-stacked .nav-link:active i,
.nav-tabs.nav-stacked .nav-link:focus i,
.nav-tabs.nav-stacked .nav-link:hover i {
  font-size: 22px;
  position: absolute;
  top: 50%;
  right: 20px;
  margin-top: -10px;
  color: #E6E6E6
}

.nav-tabs.nav-stacked .nav-link small,
.nav-tabs.nav-stacked .nav-link.disabled small,
.nav-tabs.nav-stacked .nav-link:active small,
.nav-tabs.nav-stacked .nav-link:focus small,
.nav-tabs.nav-stacked .nav-link:hover small {
  display: block;
  font-weight: 400
}

.nav-tabs.nav-stacked .active>.nav-link,
.nav-tabs.nav-stacked .active>.nav-link:hover,
.nav-tabs.nav-stacked .nav-link.active,
.nav-tabs.nav-stacked .nav-link.active:hover {
  border: 1px solid #E6E6E6!important;
  bottom: 0
}

.nav-tabs.nav-stacked .active>.nav-link:before,
.nav-tabs.nav-stacked .active>.nav-link:hover:before,
.nav-tabs.nav-stacked .nav-link.active:before,
.nav-tabs.nav-stacked .nav-link.active:hover:before {
  height: 100%;
  width: 4px;
  left: 0;
  top: 0;
  bottom: 0
}

.nav-tabs.nav-stacked .active>.nav-link i,
.nav-tabs.nav-stacked .active>.nav-link:hover i,
.nav-tabs.nav-stacked .nav-link.active i,
.nav-tabs.nav-stacked .nav-link.active:hover i {
  color: #55A79A
}

@media (min-width:576px) {
  .nav-tabs.nav-stacked .active>.nav-link,
  .nav-tabs.nav-stacked .active>.nav-link:hover,
  .nav-tabs.nav-stacked .nav-link.active,
  .nav-tabs.nav-stacked .nav-link.active:hover {
    border-right-color: #fff!important;
    margin-right: -1px
  }
  .nav-tabs.nav-stacked.nav-tabs-right {
    border-left: 1px solid #E6E6E6;
    border-right: none
  }
}

.nav-tabs.nav-stacked.nav-tabs-right .nav-item .nav-link.active,
.nav-tabs.nav-stacked.nav-tabs-right .nav-item .nav-link.active:hover {
  border: 1px solid #E6E6E6
}

@media (min-width:576px) {
  .nav-tabs.nav-stacked.nav-tabs-right .nav-item .nav-link.active,
  .nav-tabs.nav-stacked.nav-tabs-right .nav-item .nav-link.active:hover {
    border-left-color: #fff!important;
    margin-left: -1px
  }
}

.nav-tabs.nav-stacked.nav-tabs-right .nav-item .nav-link.active:before,
.nav-tabs.nav-stacked.nav-tabs-right .nav-item .nav-link.active:hover:before {
  left: auto;
  right: 0
}

.nav-tabs-bold {
  font-size: 1.1em
}

.nav-tabs-bold .nav-link {
  color: #242424;
  padding: .8em 1.2em
}

.nav-tabs-bold .nav-link.active {
  color: #55A79A
}

.nav-tabs .dropdown-menu {
  border-color: #E6E6E6
}

.page-item.active .page-link,
.page-item.active .page-link:focus,
.page-item.active .page-link:hover {
  color: #fff
}

.tab-content-bordered {
  border: 1px solid #E6E6E6;
  border-top: none;
  padding: 15px;
  margin-top: -1em
}

.nav-section-menu .nav,
.nav-section-menu.nav {
  padding: 2em 0;
  margin-left: 0;
  -ms-flex-direction: column;
  flex-direction: column
}

.nav-section-menu .nav .nav-header,
.nav-section-menu.nav .nav-header {
  padding-left: 5px;
  margin: 0;
  text-transform: uppercase;
  text-shadow: 0 1px 0 rgba(255, 255, 255, .5);
  color: #999;
  font-weight: 700;
  font-size: 11px;
  display: block
}

.nav-section-menu .nav .nav-link,
.nav-section-menu.nav .nav-link {
  color: #494949;
  color: rgba(27, 27, 27, .7);
  border-radius: 0;
  font-weight: 700;
  font-size: 18px;
  border: 1px solid #E6E6E6;
  margin: -1px -1px 0 3px;
  box-shadow: 1px 1px 1px #fff;
  background: #fcfcfc;
  text-shadow: none;
  padding: 8px 30px 8px 15px;
  position: relative;
  display: block
}

.nav-section-menu .nav .nav-link i,
.nav-section-menu.nav .nav-link i {
  font-size: 22px;
  position: absolute;
  top: 50%;
  margin-top: -11px;
  right: 25px;
  color: #E6E6E6;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg)
}

.nav-section-menu .nav .nav-link small,
.nav-section-menu.nav .nav-link small {
  display: block;
  font-weight: 400;
  color: rgba(27, 27, 27, .5)
}

.nav-section-menu .nav .nav-link:hover,
.nav-section-menu .nav .show.nav-link,
.nav-section-menu.nav .nav-link:hover,
.nav-section-menu.nav .show.nav-link {
  background: #f5f5f5
}

.nav-section-menu .nav .active .nav-link,
.nav-section-menu .nav .active .nav-link:hover,
.nav-section-menu .nav .nav-link.active,
.nav-section-menu .nav .nav-link.active:hover,
.nav-section-menu.nav .active .nav-link,
.nav-section-menu.nav .active .nav-link:hover,
.nav-section-menu.nav .nav-link.active,
.nav-section-menu.nav .nav-link.active:hover {
  border-left: 4px solid #55A79A;
  background: #fff
}

.nav-section-menu.nav-section-menu-right .nav .nav-link i,
.nav-section-menu.nav-section-menu-right.nav .nav-link i {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg)
}

.nav-section-menu .nav .nav-section-menu,
.nav-section-menu.nav .nav-section-menu {
  padding: 0;
  margin-left: 10px
}

.nav-section-menu .nav .nav-section-menu .nav-link,
.nav-section-menu.nav .nav-section-menu .nav-link {
  color: #494949;
  color: rgba(27, 27, 27, .7);
  font-weight: 400;
  font-size: 90%;
  background: #fff;
  padding: 6px 30px 5px 10px
}

.nav-section-menu .nav .nav-section-menu .nav-link:hover,
.nav-section-menu.nav .nav-section-menu .nav-link:hover {
  background: #fafafa
}

.nav-section-menu .nav-section-menu-right.nav .nav-section-menu,
.nav-section-menu.nav-section-menu-right.nav .nav-section-menu {
  margin-left: 0;
  margin-right: 10px
}

@media (min-width:768px) {
  .nav-section-menu .nav,
  .nav-section-menu.nav {
    border-right: 1px solid #E6E6E6
  }
  .nav-section-menu .nav .nav-link i,
  .nav-section-menu.nav .nav-link i {
    right: 10px;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0)
  }
  .nav-section-menu .nav .active .nav-link,
  .nav-section-menu .nav .active .nav-link:hover,
  .nav-section-menu .nav .nav-link.active,
  .nav-section-menu .nav .nav-link.active:hover,
  .nav-section-menu.nav .active .nav-link,
  .nav-section-menu.nav .active .nav-link:hover,
  .nav-section-menu.nav .nav-link.active,
  .nav-section-menu.nav .nav-link.active:hover {
    border-right-color: #fff
  }
  .nav-section-menu .nav .show.nav-link i,
  .nav-section-menu.nav .show.nav-link i {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    color: silver
  }
  .nav-section-menu.nav-section-menu-right .nav,
  .nav-section-menu.nav-section-menu-right.nav {
    border-right: none;
    border-left: 1px solid #E6E6E6
  }
  .nav-section-menu.nav-section-menu-right .nav .nav-header,
  .nav-section-menu.nav-section-menu-right.nav .nav-header {
    text-align: right;
    padding-right: 5px
  }
  .nav-section-menu.nav-section-menu-right .nav .nav-link i,
  .nav-section-menu.nav-section-menu-right.nav .nav-link i {
    right: auto;
    left: 10px;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0)
  }
  .nav-section-menu.nav-section-menu-right .nav .nav-link,
  .nav-section-menu.nav-section-menu-right.nav .nav-link {
    margin-left: -1px;
    margin-right: 0;
    padding-left: 30px;
    padding-right: 15px
  }
  .nav-section-menu.nav-section-menu-right .nav .active .nav-link,
  .nav-section-menu.nav-section-menu-right .nav .active .nav-link:hover,
  .nav-section-menu.nav-section-menu-right .nav .nav-link.active,
  .nav-section-menu.nav-section-menu-right .nav .nav-link.active:hover,
  .nav-section-menu.nav-section-menu-right.nav .active .nav-link,
  .nav-section-menu.nav-section-menu-right.nav .active .nav-link:hover,
  .nav-section-menu.nav-section-menu-right.nav .nav-link.active,
  .nav-section-menu.nav-section-menu-right.nav .nav-link.active:hover {
    border-left-color: #fff;
    border-right: 4px solid #55A79A
  }
  .nav .nav-section-menu .nav-link {
    margin-right: -2px
  }
  .nav-section-menu-right.nav .nav-section-menu .nav-link {
    margin-right: 0;
    margin-left: -2px
  }
}

.nav-steps {
  border: none;
  text-align: center;
  position: relative;
  padding: 30px 0
}

.nav-steps:after {
  background: #E6E6E6;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  content: '';
  z-index: 0;
  height: 100%;
  width: 1px;
  margin: 0 auto
}

.nav-steps .nav-link {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  text-align: center;
  border-radius: 2px;
  border: 1px solid #E6E6E6;
  background: #fff;
  display: block;
  margin-bottom: 15px;
  padding: 1rem;
  color: rgba(27, 27, 27, .8)
}

.nav-steps .nav-link:hover {
  background: #f7f7f9
}

.nav-steps .nav-link.active,
.nav-steps .nav-link.active:hover {
  background: #55A79A;
  border-color: #55A79A;
  color: #fff
}

.nav-steps .nav-item,
.nav-steps .nav-item.show {
  z-index: 1;
  position: relative;
  padding: 0
}

.nav-steps.flex-row-reverse:after,
.nav-steps.flex-row:after {
  height: 1px;
  width: 100%;
  top: 50%
}

.nav-steps.flex-row .nav-link,
.nav-steps.flex-row-reverse .nav-link {
  padding: 2rem
}

.nav-steps.flex-row-reverse.nav-steps-lg .nav-link,
.nav-steps.flex-row.nav-steps-lg .nav-link {
  padding: 3rem
}

.nav-steps.flex-row-reverse.nav-steps-circles .nav-link,
.nav-steps.flex-row.nav-steps-circles .nav-link {
  width: 120px;
  height: 120px;
  border-radius: 100%
}

.nav-steps.flex-row-reverse.nav-steps-circles.nav-steps-lg .nav-link,
.nav-steps.flex-row.nav-steps-circles.nav-steps-lg .nav-link {
  height: 160px;
  width: 160px
}

@media (min-width:576px) {
  .nav-steps.flex-sm-row-reverse:after,
  .nav-steps.flex-sm-row:after {
    height: 1px;
    width: 100%;
    top: 50%
  }
  .nav-steps.flex-sm-row .nav-link,
  .nav-steps.flex-sm-row-reverse .nav-link {
    padding: 2rem
  }
  .nav-steps.flex-sm-row-reverse.nav-steps-lg .nav-link,
  .nav-steps.flex-sm-row.nav-steps-lg .nav-link {
    padding: 3rem
  }
  .nav-steps.flex-sm-row-reverse.nav-steps-circles .nav-link,
  .nav-steps.flex-sm-row.nav-steps-circles .nav-link {
    width: 120px;
    height: 120px;
    border-radius: 100%
  }
  .nav-steps.flex-sm-row-reverse.nav-steps-circles.nav-steps-lg .nav-link,
  .nav-steps.flex-sm-row.nav-steps-circles.nav-steps-lg .nav-link {
    height: 160px;
    width: 160px
  }
}

@media (min-width:768px) {
  .nav-steps.flex-md-row-reverse:after,
  .nav-steps.flex-md-row:after {
    height: 1px;
    width: 100%;
    top: 50%
  }
  .nav-steps.flex-md-row .nav-link,
  .nav-steps.flex-md-row-reverse .nav-link {
    padding: 2rem
  }
  .nav-steps.flex-md-row-reverse.nav-steps-lg .nav-link,
  .nav-steps.flex-md-row.nav-steps-lg .nav-link {
    padding: 3rem
  }
  .nav-steps.flex-md-row-reverse.nav-steps-circles .nav-link,
  .nav-steps.flex-md-row.nav-steps-circles .nav-link {
    width: 120px;
    height: 120px;
    border-radius: 100%
  }
  .nav-steps.flex-md-row-reverse.nav-steps-circles.nav-steps-lg .nav-link,
  .nav-steps.flex-md-row.nav-steps-circles.nav-steps-lg .nav-link {
    height: 160px;
    width: 160px
  }
}

@media (min-width:992px) {
  .nav-steps.flex-lg-row-reverse:after,
  .nav-steps.flex-lg-row:after {
    height: 1px;
    width: 100%;
    top: 50%
  }
  .nav-steps.flex-lg-row .nav-link,
  .nav-steps.flex-lg-row-reverse .nav-link {
    padding: 2rem
  }
  .nav-steps.flex-lg-row-reverse.nav-steps-lg .nav-link,
  .nav-steps.flex-lg-row.nav-steps-lg .nav-link {
    padding: 3rem
  }
  .nav-steps.flex-lg-row-reverse.nav-steps-circles .nav-link,
  .nav-steps.flex-lg-row.nav-steps-circles .nav-link {
    width: 120px;
    height: 120px;
    border-radius: 100%
  }
  .nav-steps.flex-lg-row-reverse.nav-steps-circles.nav-steps-lg .nav-link,
  .nav-steps.flex-lg-row.nav-steps-circles.nav-steps-lg .nav-link {
    height: 160px;
    width: 160px
  }
}

@media (min-width:1200px) {
  .nav-steps.flex-xl-row-reverse:after,
  .nav-steps.flex-xl-row:after {
    height: 1px;
    width: 100%;
    top: 50%
  }
  .nav-steps.flex-xl-row .nav-link,
  .nav-steps.flex-xl-row-reverse .nav-link {
    padding: 2rem
  }
  .nav-steps.flex-xl-row-reverse.nav-steps-lg .nav-link,
  .nav-steps.flex-xl-row.nav-steps-lg .nav-link {
    padding: 3rem
  }
  .nav-steps.flex-xl-row-reverse.nav-steps-circles .nav-link,
  .nav-steps.flex-xl-row.nav-steps-circles .nav-link {
    width: 120px;
    height: 120px;
    border-radius: 100%
  }
  .nav-steps.flex-xl-row-reverse.nav-steps-circles.nav-steps-lg .nav-link,
  .nav-steps.flex-xl-row.nav-steps-circles.nav-steps-lg .nav-link {
    height: 160px;
    width: 160px
  }
}

.nav.collapse {
  display: none
}

.list-group-item,
.modal-progress,
.modal-static,
.nav.collapse.show {
  display: block
}

.dropdown-nav-tabs {
  margin-left: -15px;
  margin-right: -15px;
  padding: 0 15px
}

#jPanelMenu-menu .tab-content {
  padding: 0!important
}

#jPanelMenu-menu .tab-content .tab-pane {
  padding: 0 15px
}

#jPanelMenu-menu .nav-pills {
  padding: 7.5px
}

.modal,
body.modal-open {
  padding: 0!important
}

#jPanelMenu-menu .nav-tabs {
  margin-bottom: 0;
  border-bottom: 1px solid rgba(255, 255, 255, .1)
}

#jPanelMenu-menu .nav-tabs .nav-link,
#jPanelMenu-menu .nav-tabs .nav-link.disabled,
#jPanelMenu-menu .nav-tabs .nav-link:active,
#jPanelMenu-menu .nav-tabs .nav-link:focus,
#jPanelMenu-menu .nav-tabs .nav-link:hover {
  border-radius: 0!important;
  margin-right: 0;
  margin-bottom: 0;
  background: 0 0;
  border: 1px solid rgba(255, 255, 255, .1)!important;
  border-bottom: none!important
}

#jPanelMenu-menu .nav-tabs .nav-link:hover {
  background: #282828;
  cursor: pointer
}

#jPanelMenu-menu .nav-tabs .active>.nav-link,
#jPanelMenu-menu .nav-tabs .active>.nav-link:focus,
#jPanelMenu-menu .nav-tabs .active>.nav-link:hover,
#jPanelMenu-menu .nav-tabs .nav-link.active,
#jPanelMenu-menu .nav-tabs .nav-link.active:focus,
#jPanelMenu-menu .nav-tabs .nav-link.active:hover {
  color: #55A79A;
  border: 1px solid rgba(255, 255, 255, .1)!important;
  position: relative;
  background: #282828
}

#jPanelMenu-menu .nav-tabs .active>.nav-link:before,
#jPanelMenu-menu .nav-tabs .active>.nav-link:focus:before,
#jPanelMenu-menu .nav-tabs .active>.nav-link:hover:before,
#jPanelMenu-menu .nav-tabs .nav-link.active:before,
#jPanelMenu-menu .nav-tabs .nav-link.active:focus:before,
#jPanelMenu-menu .nav-tabs .nav-link.active:hover:before {
  height: 2px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  content: ''
}

#jPanelMenu-menu .nav-tabs .nav-item.show .nav-link,
#jPanelMenu-menu .nav-tabs .nav-item.show .nav-link.active,
#jPanelMenu-menu .nav-tabs .nav-item.show .nav-link.active:active,
#jPanelMenu-menu .nav-tabs .nav-item.show .nav-link.active:focus,
#jPanelMenu-menu .nav-tabs .nav-item.show .nav-link.active:hover,
#jPanelMenu-menu .nav-tabs .nav-item.show .nav-link:active,
#jPanelMenu-menu .nav-tabs .nav-item.show .nav-link:focus,
#jPanelMenu-menu .nav-tabs .nav-item.show .nav-link:hover {
  background: 0 0
}

body.modal-open .jPanelMenu-panel {
  position: static!important
}

.modal-static {
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  z-index: 1!important;
  margin-bottom: 1em
}

.modal,
.modal-dialog {
  z-index: 1050
}

.modal-static .modal-dialog {
  left: auto;
  margin-right: auto;
  margin-left: auto;
  padding: 0!important
}

.modal-content {
  box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
  border-radius: 3px
}

.modal-backdrop {
  z-index: 1040;
  background-color: #000
}

.modal-backdrop.fade {
  opacity: 0
}

.modal-backdrop.show {
  opacity: .5
}

.modal-header {
  padding: 15px;
  min-height: 16.5
}

.modal-title {
  margin: 0
}

.modal-body {
  padding: 15px
}

.modal-footer {
  margin-top: 0;
  padding: 14px 15px 15px
}

@media (min-width:576px) {
  .modal-dialog {
    padding-top: 5%;
    padding-bottom: 30px
  }
  .modal-content {
    box-shadow: 0 5px 15px rgba(0, 0, 0, .5)
  }
}

.modal-progress {
  height: 5px;
  background: #55A79A;
  width: 0;
  -webkit-transition: width .2s ease-in-out;
  -moz-transition: width .2s ease-in-out;
  -ms-transition: width .2s ease-in-out;
  -o-transition: width .2s ease-in-out;
  transition: width .2s ease-in-out
}

.modal-fs,
.modal-fs .modal-content {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0
}

.modal-fs {
  max-width: 100%!important
}

.modal-fs .modal-content {
  position: fixed;
  max-width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 0;
  border: none
}

.list-group-item-secondary,
a.list-group-item-secondary,
button.list-group-item-secondary {
  background-color: #fff;
  color: #1b1b1b
}

a.list-group-item-secondary:focus,
a.list-group-item-secondary:hover,
button.list-group-item-secondary:focus,
button.list-group-item-secondary:hover {
  color: #1b1b1b;
  background-color: #f2f2f2
}

.list-group-item-inverse,
a.list-group-item-inverse,
button.list-group-item-inverse {
  background-color: #292b2c;
  color: #fff
}

a.list-group-item-inverse:focus,
a.list-group-item-inverse:hover,
button.list-group-item-inverse:focus,
button.list-group-item-inverse:hover {
  color: #fff;
  background-color: #000
}

.list-group-item .badge {
  margin-right: .5rem
}

.list-group-striped {
  list-style: none;
  padding: 0;
  margin: 0
}

.mt-6,
.my-6 {
  margin-top: 4.5rem!important
}

.list-group-striped .list-group-item.w-odd,
.list-group-striped .list-group-item:nth-child(odd) {
  background: #f3f3f3
}

.list-item-icon {
  width: 1.28571429em;
  text-align: center
}

.card {
  border-radius: 2px;
  position: relative;
  margin-bottom: 1rem
}

.card.clickable-element:hover {
  box-shadow: 1px 1px 5px #d2d2d2
}

.card-group .card {
  margin-bottom: 0
}

@media (min-width:576px) {
  .card-group .card+.card-outline-danger,
  .card-group .card+.card-outline-info,
  .card-group .card+.card-outline-primary,
  .card-group .card+.card-outline-secondary,
  .card-group .card+.card-outline-success,
  .card-group .card+.card-outline-warning {
    position: relative;
    z-index: 1;
    left: -1px
  }
  .card-group .card+.card-outline-danger+.card-outline-primary,
  .card-group .card+.card-outline-info+.card-outline-primary,
  .card-group .card+.card-outline-primary+.card-outline-primary,
  .card-group .card+.card-outline-secondary+.card-outline-primary,
  .card-group .card+.card-outline-success+.card-outline-primary,
  .card-group .card+.card-outline-warning+.card-outline-primary {
    border-left: none
  }
  .card-group .card+.card-outline-secondary {
    border-left: 1px solid #ccc
  }
  .card-group .card+.card-outline-success {
    border-left: 1px solid #5cb85c
  }
  .card-group .card+.card-outline-info {
    border-left: 1px solid #5bc0de
  }
  .card-group .card+.card-outline-warning {
    border-left: 1px solid #f0ad4e
  }
  .card-group .card+.card-outline-danger {
    border-left: 1px solid #d9534f
  }
}

.card-ribbon {
  width: 200px;
  padding: 2px 5px;
  position: absolute;
  text-align: center;
  font-size: 12px;
  z-index: 1
}

.card-ribbon-top {
  top: 20px
}

.card-ribbon-bottom {
  bottom: 20px
}

.card-ribbon-left {
  left: -70px
}

.card-ribbon-right {
  right: -70px
}

.flex-direction-nav a,
.flex-direction-nav a.flex-next,
.flex-direction-nav a.tp-rightarrow {
  right: 0;
  text-align: center
}

.card-ribbon-bottom.card-ribbon-right,
.card-ribbon-top.card-ribbon-left {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg)
}

.card-ribbon-bottom.card-ribbon-left,
.card-ribbon-top.card-ribbon-right {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg)
}

.card-block-overlap {
  margin-left: -4px!important;
  margin-right: -4px!important
}

.mr-6,
.mx-6 {
  margin-right: 4.5rem!important
}

@media (min-width:576px) {
  .card-grid-overlap .col-lg,
  .card-grid-overlap .col-lg-1,
  .card-grid-overlap .col-lg-10,
  .card-grid-overlap .col-lg-11,
  .card-grid-overlap .col-lg-12,
  .card-grid-overlap .col-lg-2,
  .card-grid-overlap .col-lg-3,
  .card-grid-overlap .col-lg-4,
  .card-grid-overlap .col-lg-5,
  .card-grid-overlap .col-lg-6,
  .card-grid-overlap .col-lg-7,
  .card-grid-overlap .col-lg-8,
  .card-grid-overlap .col-lg-9,
  .card-grid-overlap .col-md,
  .card-grid-overlap .col-md-1,
  .card-grid-overlap .col-md-10,
  .card-grid-overlap .col-md-11,
  .card-grid-overlap .col-md-12,
  .card-grid-overlap .col-md-2,
  .card-grid-overlap .col-md-3,
  .card-grid-overlap .col-md-4,
  .card-grid-overlap .col-md-5,
  .card-grid-overlap .col-md-6,
  .card-grid-overlap .col-md-7,
  .card-grid-overlap .col-md-8,
  .card-grid-overlap .col-md-9,
  .card-grid-overlap .col-sm,
  .card-grid-overlap .col-sm-1,
  .card-grid-overlap .col-sm-10,
  .card-grid-overlap .col-sm-11,
  .card-grid-overlap .col-sm-12,
  .card-grid-overlap .col-sm-2,
  .card-grid-overlap .col-sm-3,
  .card-grid-overlap .col-sm-4,
  .card-grid-overlap .col-sm-5,
  .card-grid-overlap .col-sm-6,
  .card-grid-overlap .col-sm-7,
  .card-grid-overlap .col-sm-8,
  .card-grid-overlap .col-sm-9,
  .card-grid-overlap .col-xl,
  .card-grid-overlap .col-xl-1,
  .card-grid-overlap .col-xl-10,
  .card-grid-overlap .col-xl-11,
  .card-grid-overlap .col-xl-12,
  .card-grid-overlap .col-xl-2,
  .card-grid-overlap .col-xl-3,
  .card-grid-overlap .col-xl-4,
  .card-grid-overlap .col-xl-5,
  .card-grid-overlap .col-xl-6,
  .card-grid-overlap .col-xl-7,
  .card-grid-overlap .col-xl-8,
  .card-grid-overlap .col-xl-9,
  .card-grid-overlap .col-xs,
  .card-grid-overlap .col-xs-1,
  .card-grid-overlap .col-xs-10,
  .card-grid-overlap .col-xs-11,
  .card-grid-overlap .col-xs-12,
  .card-grid-overlap .col-xs-2,
  .card-grid-overlap .col-xs-3,
  .card-grid-overlap .col-xs-4,
  .card-grid-overlap .col-xs-5,
  .card-grid-overlap .col-xs-6,
  .card-grid-overlap .col-xs-7,
  .card-grid-overlap .col-xs-8,
  .card-grid-overlap .col-xs-9 {
    padding-left: 0;
    padding-right: 0
  }
  .card-grid-overlap .card {
    margin-left: -1px
  }
  .card-grid-overlap .card-outline-danger,
  .card-grid-overlap .card-outline-info,
  .card-grid-overlap .card-outline-primary,
  .card-grid-overlap .card-outline-secondary,
  .card-grid-overlap .card-outline-success,
  .card-grid-overlap .card-outline-warning {
    position: relative;
    z-index: 1
  }
}

.ml-6,
.mx-6 {
  margin-left: 4.5rem!important
}

.card-accordion .card-header a[data-toggle=collapse] {
  display: block;
  padding: 15px
}

.card-accordion .card-header a[data-toggle=collapse]:after {
  font-family: FontAwesome;
  font-style: normal;
  speak: none;
  display: inline-block;
  content: "";
  float: right;
  line-height: 1;
  width: 22px;
  font-size: 20px;
  text-align: center
}

.card-accordion .card-header a[data-toggle=collapse].collapsed:after {
  font-family: FontAwesome;
  font-style: normal;
  speak: none;
  display: inline-block;
  content: ""
}

.flex-direction-nav a.flex-next:before,
.flex-direction-nav a.tp-rightarrow:before,
.flex-direction-nav a:before {
  content: '\f105'
}

.card-accordion .card-header a[data-toggle=collapse]:hover {
  text-decoration: none
}

.card-accordion.card-accordion-icons-left .card-header a[data-toggle=collapse]:after {
  float: left;
  padding-right: .6em
}

.card-accordion.card-accordion-list-style .card {
  border-left: none;
  border-right: none;
  border-radius: 0;
  margin-bottom: -1px
}

.mb-6,
.my-6 {
  margin-bottom: 4.5rem!important
}

.card-accordion.card-accordion-list-style .card-header {
  background: 0 0;
  border: none
}

.card-accordion.card-accordion-list-style .card-header a[data-toggle=collapse] {
  padding: 8px 15px
}

.pr-6,
.px-6 {
  padding-right: 4.5rem!important
}

.pl-6,
.px-6 {
  padding-left: 4.5rem!important
}

.pt-6,
.py-6 {
  padding-top: 4.5rem!important
}

.pb-6,
.py-6 {
  padding-bottom: 4.5rem!important
}

.card-accordion.card-accordion-list-style .card-header a[data-toggle=collapse]:after {
  font-size: 16px;
  line-height: 20px
}

.m-6 {
  margin: 4.5rem!important
}

.mr-7,
.mx-7 {
  margin-right: 6rem!important
}

.ml-7,
.mx-7 {
  margin-left: 6rem!important
}

.mt-7,
.my-7 {
  margin-top: 6rem!important
}

.mb-7,
.my-7 {
  margin-bottom: 6rem!important
}

.m-7 {
  margin: 6rem!important
}

.mr-8,
.mx-8 {
  margin-right: 7.5rem!important
}

.ml-8,
.mx-8 {
  margin-left: 7.5rem!important
}

.mt-8,
.my-8 {
  margin-top: 7.5rem!important
}

.mb-8,
.my-8 {
  margin-bottom: 7.5rem!important
}

.m-8 {
  margin: 7.5rem!important
}

.mr-9,
.mx-9 {
  margin-right: 9rem!important
}

.ml-9,
.mx-9 {
  margin-left: 9rem!important
}

.mt-9,
.my-9 {
  margin-top: 9rem!important
}

.mb-9,
.my-9 {
  margin-bottom: 9rem!important
}

.m-9 {
  margin: 9rem!important
}

.mr-10,
.mx-10 {
  margin-right: 10.5rem!important
}

.ml-10,
.mx-10 {
  margin-left: 10.5rem!important
}

.mt-10,
.my-10 {
  margin-top: 10.5rem!important
}

.mb-10,
.my-10 {
  margin-bottom: 10.5rem!important
}

.m-10 {
  margin: 10.5rem!important
}

.mr-auto,
.mx-auto {
  margin-right: auto!important
}

.ml-auto,
.mx-auto {
  margin-left: auto!important
}

.mt-auto,
.my-auto {
  margin-top: auto!important
}

.mb-auto,
.my-auto {
  margin-bottom: auto!important
}

.p-6 {
  padding: 4.5rem!important
}

.pr-7,
.px-7 {
  padding-right: 6rem!important
}

.pl-7,
.px-7 {
  padding-left: 6rem!important
}

.pt-7,
.py-7 {
  padding-top: 6rem!important
}

.pb-7,
.py-7 {
  padding-bottom: 6rem!important
}

.p-7 {
  padding: 6rem!important
}

.pr-8,
.px-8 {
  padding-right: 7.5rem!important
}

.pl-8,
.px-8 {
  padding-left: 7.5rem!important
}

.pt-8,
.py-8 {
  padding-top: 7.5rem!important
}

.pb-8,
.py-8 {
  padding-bottom: 7.5rem!important
}

.p-8 {
  padding: 7.5rem!important
}

.pr-9,
.px-9 {
  padding-right: 9rem!important
}

.pl-9,
.px-9 {
  padding-left: 9rem!important
}

.pt-9,
.py-9 {
  padding-top: 9rem!important
}

.pb-9,
.py-9 {
  padding-bottom: 9rem!important
}

.p-9 {
  padding: 9rem!important
}

.pr-10,
.px-10 {
  padding-right: 10.5rem!important
}

.pl-10,
.px-10 {
  padding-left: 10.5rem!important
}

.pt-10,
.py-10 {
  padding-top: 10.5rem!important
}

.pb-10,
.py-10 {
  padding-bottom: 10.5rem!important
}

.p-10 {
  padding: 10.5rem!important
}

.m-auto {
  margin: auto!important
}

@media (min-width:576px) {
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 4.5rem!important
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 4.5rem!important
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 4.5rem!important
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 4.5rem!important
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 4.5rem!important
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 4.5rem!important
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 4.5rem!important
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 4.5rem!important
  }
  .m-sm-6 {
    margin: 4.5rem!important
  }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 6rem!important
  }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 6rem!important
  }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 6rem!important
  }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 6rem!important
  }
  .m-sm-7 {
    margin: 6rem!important
  }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 7.5rem!important
  }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 7.5rem!important
  }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 7.5rem!important
  }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 7.5rem!important
  }
  .m-sm-8 {
    margin: 7.5rem!important
  }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 9rem!important
  }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 9rem!important
  }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 9rem!important
  }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 9rem!important
  }
  .m-sm-9 {
    margin: 9rem!important
  }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 10.5rem!important
  }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 10.5rem!important
  }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 10.5rem!important
  }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 10.5rem!important
  }
  .m-sm-10 {
    margin: 10.5rem!important
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto!important
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto!important
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto!important
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto!important
  }
  .p-sm-6 {
    padding: 4.5rem!important
  }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 6rem!important
  }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 6rem!important
  }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 6rem!important
  }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 6rem!important
  }
  .p-sm-7 {
    padding: 6rem!important
  }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 7.5rem!important
  }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 7.5rem!important
  }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 7.5rem!important
  }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 7.5rem!important
  }
  .p-sm-8 {
    padding: 7.5rem!important
  }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 9rem!important
  }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 9rem!important
  }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 9rem!important
  }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 9rem!important
  }
  .p-sm-9 {
    padding: 9rem!important
  }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 10.5rem!important
  }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 10.5rem!important
  }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 10.5rem!important
  }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 10.5rem!important
  }
  .p-sm-10 {
    padding: 10.5rem!important
  }
  .m-sm-auto {
    margin: auto!important
  }
}

@media (min-width:768px) {
  .mr-md-6,
  .mx-md-6 {
    margin-right: 4.5rem!important
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 4.5rem!important
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 4.5rem!important
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 4.5rem!important
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 4.5rem!important
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 4.5rem!important
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 4.5rem!important
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 4.5rem!important
  }
  .m-md-6 {
    margin: 4.5rem!important
  }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 6rem!important
  }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 6rem!important
  }
  .mt-md-7,
  .my-md-7 {
    margin-top: 6rem!important
  }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 6rem!important
  }
  .m-md-7 {
    margin: 6rem!important
  }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 7.5rem!important
  }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 7.5rem!important
  }
  .mt-md-8,
  .my-md-8 {
    margin-top: 7.5rem!important
  }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 7.5rem!important
  }
  .m-md-8 {
    margin: 7.5rem!important
  }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 9rem!important
  }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 9rem!important
  }
  .mt-md-9,
  .my-md-9 {
    margin-top: 9rem!important
  }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 9rem!important
  }
  .m-md-9 {
    margin: 9rem!important
  }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 10.5rem!important
  }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 10.5rem!important
  }
  .mt-md-10,
  .my-md-10 {
    margin-top: 10.5rem!important
  }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 10.5rem!important
  }
  .m-md-10 {
    margin: 10.5rem!important
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto!important
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto!important
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto!important
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto!important
  }
  .p-md-6 {
    padding: 4.5rem!important
  }
  .pr-md-7,
  .px-md-7 {
    padding-right: 6rem!important
  }
  .pl-md-7,
  .px-md-7 {
    padding-left: 6rem!important
  }
  .pt-md-7,
  .py-md-7 {
    padding-top: 6rem!important
  }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 6rem!important
  }
  .p-md-7 {
    padding: 6rem!important
  }
  .pr-md-8,
  .px-md-8 {
    padding-right: 7.5rem!important
  }
  .pl-md-8,
  .px-md-8 {
    padding-left: 7.5rem!important
  }
  .pt-md-8,
  .py-md-8 {
    padding-top: 7.5rem!important
  }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 7.5rem!important
  }
  .p-md-8 {
    padding: 7.5rem!important
  }
  .pr-md-9,
  .px-md-9 {
    padding-right: 9rem!important
  }
  .pl-md-9,
  .px-md-9 {
    padding-left: 9rem!important
  }
  .pt-md-9,
  .py-md-9 {
    padding-top: 9rem!important
  }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 9rem!important
  }
  .p-md-9 {
    padding: 9rem!important
  }
  .pr-md-10,
  .px-md-10 {
    padding-right: 10.5rem!important
  }
  .pl-md-10,
  .px-md-10 {
    padding-left: 10.5rem!important
  }
  .pt-md-10,
  .py-md-10 {
    padding-top: 10.5rem!important
  }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 10.5rem!important
  }
  .p-md-10 {
    padding: 10.5rem!important
  }
  .m-md-auto {
    margin: auto!important
  }
}

@media (min-width:992px) {
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 4.5rem!important
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 4.5rem!important
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 4.5rem!important
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 4.5rem!important
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 4.5rem!important
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 4.5rem!important
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 4.5rem!important
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 4.5rem!important
  }
  .m-lg-6 {
    margin: 4.5rem!important
  }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 6rem!important
  }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 6rem!important
  }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 6rem!important
  }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 6rem!important
  }
  .m-lg-7 {
    margin: 6rem!important
  }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 7.5rem!important
  }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 7.5rem!important
  }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 7.5rem!important
  }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 7.5rem!important
  }
  .m-lg-8 {
    margin: 7.5rem!important
  }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 9rem!important
  }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 9rem!important
  }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 9rem!important
  }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 9rem!important
  }
  .m-lg-9 {
    margin: 9rem!important
  }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 10.5rem!important
  }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 10.5rem!important
  }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 10.5rem!important
  }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 10.5rem!important
  }
  .m-lg-10 {
    margin: 10.5rem!important
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto!important
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto!important
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto!important
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto!important
  }
  .p-lg-6 {
    padding: 4.5rem!important
  }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 6rem!important
  }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 6rem!important
  }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 6rem!important
  }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 6rem!important
  }
  .p-lg-7 {
    padding: 6rem!important
  }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 7.5rem!important
  }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 7.5rem!important
  }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 7.5rem!important
  }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 7.5rem!important
  }
  .p-lg-8 {
    padding: 7.5rem!important
  }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 9rem!important
  }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 9rem!important
  }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 9rem!important
  }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 9rem!important
  }
  .p-lg-9 {
    padding: 9rem!important
  }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 10.5rem!important
  }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 10.5rem!important
  }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 10.5rem!important
  }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 10.5rem!important
  }
  .p-lg-10 {
    padding: 10.5rem!important
  }
  .m-lg-auto {
    margin: auto!important
  }
}

@media (min-width:1200px) {
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 4.5rem!important
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 4.5rem!important
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 4.5rem!important
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 4.5rem!important
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 4.5rem!important
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 4.5rem!important
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 4.5rem!important
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 4.5rem!important
  }
  .m-xl-6 {
    margin: 4.5rem!important
  }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 6rem!important
  }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 6rem!important
  }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 6rem!important
  }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 6rem!important
  }
  .m-xl-7 {
    margin: 6rem!important
  }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 7.5rem!important
  }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 7.5rem!important
  }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 7.5rem!important
  }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 7.5rem!important
  }
  .m-xl-8 {
    margin: 7.5rem!important
  }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 9rem!important
  }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 9rem!important
  }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 9rem!important
  }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 9rem!important
  }
  .m-xl-9 {
    margin: 9rem!important
  }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 10.5rem!important
  }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 10.5rem!important
  }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 10.5rem!important
  }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 10.5rem!important
  }
  .m-xl-10 {
    margin: 10.5rem!important
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto!important
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto!important
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto!important
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto!important
  }
  .p-xl-6 {
    padding: 4.5rem!important
  }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 6rem!important
  }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 6rem!important
  }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 6rem!important
  }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 6rem!important
  }
  .p-xl-7 {
    padding: 6rem!important
  }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 7.5rem!important
  }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 7.5rem!important
  }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 7.5rem!important
  }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 7.5rem!important
  }
  .p-xl-8 {
    padding: 7.5rem!important
  }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 9rem!important
  }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 9rem!important
  }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 9rem!important
  }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 9rem!important
  }
  .p-xl-9 {
    padding: 9rem!important
  }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 10.5rem!important
  }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 10.5rem!important
  }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 10.5rem!important
  }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 10.5rem!important
  }
  .p-xl-10 {
    padding: 10.5rem!important
  }
  .m-xl-auto {
    margin: auto!important
  }
}

.mr-1-neg,
.mx-1-neg {
  margin-right: -.25rem!important
}

.ml-1-neg,
.mx-1-neg {
  margin-left: -.25rem!important
}

.mt-1-neg,
.my-1-neg {
  margin-top: -.25rem!important
}

.mb-1-neg,
.my-1-neg {
  margin-bottom: -.25rem!important
}

.m-1-neg {
  margin: -.25rem!important
}

.mr-2-neg,
.mx-2-neg {
  margin-right: -.5rem!important
}

.ml-2-neg,
.mx-2-neg {
  margin-left: -.5rem!important
}

.mt-2-neg,
.my-2-neg {
  margin-top: -.5rem!important
}

.mb-2-neg,
.my-2-neg {
  margin-bottom: -.5rem!important
}

.m-2-neg {
  margin: -.5rem!important
}

.mr-3-neg,
.mx-3-neg {
  margin-right: -1rem!important
}

.ml-3-neg,
.mx-3-neg {
  margin-left: -1rem!important
}

.mt-3-neg,
.my-3-neg {
  margin-top: -1rem!important
}

.mb-3-neg,
.my-3-neg {
  margin-bottom: -1rem!important
}

.m-3-neg {
  margin: -1rem!important
}

.mr-4-neg,
.mx-4-neg {
  margin-right: -1.5rem!important
}

.ml-4-neg,
.mx-4-neg {
  margin-left: -1.5rem!important
}

.mt-4-neg,
.my-4-neg {
  margin-top: -1.5rem!important
}

.mb-4-neg,
.my-4-neg {
  margin-bottom: -1.5rem!important
}

.m-4-neg {
  margin: -1.5rem!important
}

.mr-5-neg,
.mx-5-neg {
  margin-right: -3rem!important
}

.ml-5-neg,
.mx-5-neg {
  margin-left: -3rem!important
}

.mt-5-neg,
.my-5-neg {
  margin-top: -3rem!important
}

.mb-5-neg,
.my-5-neg {
  margin-bottom: -3rem!important
}

.m-5-neg {
  margin: -3rem!important
}

.mr-6-neg,
.mx-6-neg {
  margin-right: -4.5rem!important
}

.ml-6-neg,
.mx-6-neg {
  margin-left: -4.5rem!important
}

.mt-6-neg,
.my-6-neg {
  margin-top: -4.5rem!important
}

.mb-6-neg,
.my-6-neg {
  margin-bottom: -4.5rem!important
}

.m-6-neg {
  margin: -4.5rem!important
}

.mr-7-neg,
.mx-7-neg {
  margin-right: -6rem!important
}

.ml-7-neg,
.mx-7-neg {
  margin-left: -6rem!important
}

.mt-7-neg,
.my-7-neg {
  margin-top: -6rem!important
}

.mb-7-neg,
.my-7-neg {
  margin-bottom: -6rem!important
}

.m-7-neg {
  margin: -6rem!important
}

.mr-8-neg,
.mx-8-neg {
  margin-right: -7.5rem!important
}

.ml-8-neg,
.mx-8-neg {
  margin-left: -7.5rem!important
}

.mt-8-neg,
.my-8-neg {
  margin-top: -7.5rem!important
}

.mb-8-neg,
.my-8-neg {
  margin-bottom: -7.5rem!important
}

.m-8-neg {
  margin: -7.5rem!important
}

.mr-9-neg,
.mx-9-neg {
  margin-right: -9rem!important
}

.ml-9-neg,
.mx-9-neg {
  margin-left: -9rem!important
}

.mt-9-neg,
.my-9-neg {
  margin-top: -9rem!important
}

.mb-9-neg,
.my-9-neg {
  margin-bottom: -9rem!important
}

.m-9-neg {
  margin: -9rem!important
}

.mr-10-neg,
.mx-10-neg {
  margin-right: -10.5rem!important
}

.ml-10-neg,
.mx-10-neg {
  margin-left: -10.5rem!important
}

.mt-10-neg,
.my-10-neg {
  margin-top: -10.5rem!important
}

.mb-10-neg,
.my-10-neg {
  margin-bottom: -10.5rem!important
}

.m-10-neg {
  margin: -10.5rem!important
}

@media (min-width:576px) {
  .mr-sm-1-neg,
  .mx-sm-1-neg {
    margin-right: -.25rem!important
  }
  .ml-sm-1-neg,
  .mx-sm-1-neg {
    margin-left: -.25rem!important
  }
  .mt-sm-1-neg,
  .my-sm-1-neg {
    margin-top: -.25rem!important
  }
  .mb-sm-1-neg,
  .my-sm-1-neg {
    margin-bottom: -.25rem!important
  }
  .m-sm-1-neg {
    margin: -.25rem!important
  }
  .mr-sm-2-neg,
  .mx-sm-2-neg {
    margin-right: -.5rem!important
  }
  .ml-sm-2-neg,
  .mx-sm-2-neg {
    margin-left: -.5rem!important
  }
  .mt-sm-2-neg,
  .my-sm-2-neg {
    margin-top: -.5rem!important
  }
  .mb-sm-2-neg,
  .my-sm-2-neg {
    margin-bottom: -.5rem!important
  }
  .m-sm-2-neg {
    margin: -.5rem!important
  }
  .mr-sm-3-neg,
  .mx-sm-3-neg {
    margin-right: -1rem!important
  }
  .ml-sm-3-neg,
  .mx-sm-3-neg {
    margin-left: -1rem!important
  }
  .mt-sm-3-neg,
  .my-sm-3-neg {
    margin-top: -1rem!important
  }
  .mb-sm-3-neg,
  .my-sm-3-neg {
    margin-bottom: -1rem!important
  }
  .m-sm-3-neg {
    margin: -1rem!important
  }
  .mr-sm-4-neg,
  .mx-sm-4-neg {
    margin-right: -1.5rem!important
  }
  .ml-sm-4-neg,
  .mx-sm-4-neg {
    margin-left: -1.5rem!important
  }
  .mt-sm-4-neg,
  .my-sm-4-neg {
    margin-top: -1.5rem!important
  }
  .mb-sm-4-neg,
  .my-sm-4-neg {
    margin-bottom: -1.5rem!important
  }
  .m-sm-4-neg {
    margin: -1.5rem!important
  }
  .mr-sm-5-neg,
  .mx-sm-5-neg {
    margin-right: -3rem!important
  }
  .ml-sm-5-neg,
  .mx-sm-5-neg {
    margin-left: -3rem!important
  }
  .mt-sm-5-neg,
  .my-sm-5-neg {
    margin-top: -3rem!important
  }
  .mb-sm-5-neg,
  .my-sm-5-neg {
    margin-bottom: -3rem!important
  }
  .m-sm-5-neg {
    margin: -3rem!important
  }
  .mr-sm-6-neg,
  .mx-sm-6-neg {
    margin-right: -4.5rem!important
  }
  .ml-sm-6-neg,
  .mx-sm-6-neg {
    margin-left: -4.5rem!important
  }
  .mt-sm-6-neg,
  .my-sm-6-neg {
    margin-top: -4.5rem!important
  }
  .mb-sm-6-neg,
  .my-sm-6-neg {
    margin-bottom: -4.5rem!important
  }
  .m-sm-6-neg {
    margin: -4.5rem!important
  }
  .mr-sm-7-neg,
  .mx-sm-7-neg {
    margin-right: -6rem!important
  }
  .ml-sm-7-neg,
  .mx-sm-7-neg {
    margin-left: -6rem!important
  }
  .mt-sm-7-neg,
  .my-sm-7-neg {
    margin-top: -6rem!important
  }
  .mb-sm-7-neg,
  .my-sm-7-neg {
    margin-bottom: -6rem!important
  }
  .m-sm-7-neg {
    margin: -6rem!important
  }
  .mr-sm-8-neg,
  .mx-sm-8-neg {
    margin-right: -7.5rem!important
  }
  .ml-sm-8-neg,
  .mx-sm-8-neg {
    margin-left: -7.5rem!important
  }
  .mt-sm-8-neg,
  .my-sm-8-neg {
    margin-top: -7.5rem!important
  }
  .mb-sm-8-neg,
  .my-sm-8-neg {
    margin-bottom: -7.5rem!important
  }
  .m-sm-8-neg {
    margin: -7.5rem!important
  }
  .mr-sm-9-neg,
  .mx-sm-9-neg {
    margin-right: -9rem!important
  }
  .ml-sm-9-neg,
  .mx-sm-9-neg {
    margin-left: -9rem!important
  }
  .mt-sm-9-neg,
  .my-sm-9-neg {
    margin-top: -9rem!important
  }
  .mb-sm-9-neg,
  .my-sm-9-neg {
    margin-bottom: -9rem!important
  }
  .m-sm-9-neg {
    margin: -9rem!important
  }
  .mr-sm-10-neg,
  .mx-sm-10-neg {
    margin-right: -10.5rem!important
  }
  .ml-sm-10-neg,
  .mx-sm-10-neg {
    margin-left: -10.5rem!important
  }
  .mt-sm-10-neg,
  .my-sm-10-neg {
    margin-top: -10.5rem!important
  }
  .mb-sm-10-neg,
  .my-sm-10-neg {
    margin-bottom: -10.5rem!important
  }
  .m-sm-10-neg {
    margin: -10.5rem!important
  }
}

@media (min-width:768px) {
  .mr-md-1-neg,
  .mx-md-1-neg {
    margin-right: -.25rem!important
  }
  .ml-md-1-neg,
  .mx-md-1-neg {
    margin-left: -.25rem!important
  }
  .mt-md-1-neg,
  .my-md-1-neg {
    margin-top: -.25rem!important
  }
  .mb-md-1-neg,
  .my-md-1-neg {
    margin-bottom: -.25rem!important
  }
  .m-md-1-neg {
    margin: -.25rem!important
  }
  .mr-md-2-neg,
  .mx-md-2-neg {
    margin-right: -.5rem!important
  }
  .ml-md-2-neg,
  .mx-md-2-neg {
    margin-left: -.5rem!important
  }
  .mt-md-2-neg,
  .my-md-2-neg {
    margin-top: -.5rem!important
  }
  .mb-md-2-neg,
  .my-md-2-neg {
    margin-bottom: -.5rem!important
  }
  .m-md-2-neg {
    margin: -.5rem!important
  }
  .mr-md-3-neg,
  .mx-md-3-neg {
    margin-right: -1rem!important
  }
  .ml-md-3-neg,
  .mx-md-3-neg {
    margin-left: -1rem!important
  }
  .mt-md-3-neg,
  .my-md-3-neg {
    margin-top: -1rem!important
  }
  .mb-md-3-neg,
  .my-md-3-neg {
    margin-bottom: -1rem!important
  }
  .m-md-3-neg {
    margin: -1rem!important
  }
  .mr-md-4-neg,
  .mx-md-4-neg {
    margin-right: -1.5rem!important
  }
  .ml-md-4-neg,
  .mx-md-4-neg {
    margin-left: -1.5rem!important
  }
  .mt-md-4-neg,
  .my-md-4-neg {
    margin-top: -1.5rem!important
  }
  .mb-md-4-neg,
  .my-md-4-neg {
    margin-bottom: -1.5rem!important
  }
  .m-md-4-neg {
    margin: -1.5rem!important
  }
  .mr-md-5-neg,
  .mx-md-5-neg {
    margin-right: -3rem!important
  }
  .ml-md-5-neg,
  .mx-md-5-neg {
    margin-left: -3rem!important
  }
  .mt-md-5-neg,
  .my-md-5-neg {
    margin-top: -3rem!important
  }
  .mb-md-5-neg,
  .my-md-5-neg {
    margin-bottom: -3rem!important
  }
  .m-md-5-neg {
    margin: -3rem!important
  }
  .mr-md-6-neg,
  .mx-md-6-neg {
    margin-right: -4.5rem!important
  }
  .ml-md-6-neg,
  .mx-md-6-neg {
    margin-left: -4.5rem!important
  }
  .mt-md-6-neg,
  .my-md-6-neg {
    margin-top: -4.5rem!important
  }
  .mb-md-6-neg,
  .my-md-6-neg {
    margin-bottom: -4.5rem!important
  }
  .m-md-6-neg {
    margin: -4.5rem!important
  }
  .mr-md-7-neg,
  .mx-md-7-neg {
    margin-right: -6rem!important
  }
  .ml-md-7-neg,
  .mx-md-7-neg {
    margin-left: -6rem!important
  }
  .mt-md-7-neg,
  .my-md-7-neg {
    margin-top: -6rem!important
  }
  .mb-md-7-neg,
  .my-md-7-neg {
    margin-bottom: -6rem!important
  }
  .m-md-7-neg {
    margin: -6rem!important
  }
  .mr-md-8-neg,
  .mx-md-8-neg {
    margin-right: -7.5rem!important
  }
  .ml-md-8-neg,
  .mx-md-8-neg {
    margin-left: -7.5rem!important
  }
  .mt-md-8-neg,
  .my-md-8-neg {
    margin-top: -7.5rem!important
  }
  .mb-md-8-neg,
  .my-md-8-neg {
    margin-bottom: -7.5rem!important
  }
  .m-md-8-neg {
    margin: -7.5rem!important
  }
  .mr-md-9-neg,
  .mx-md-9-neg {
    margin-right: -9rem!important
  }
  .ml-md-9-neg,
  .mx-md-9-neg {
    margin-left: -9rem!important
  }
  .mt-md-9-neg,
  .my-md-9-neg {
    margin-top: -9rem!important
  }
  .mb-md-9-neg,
  .my-md-9-neg {
    margin-bottom: -9rem!important
  }
  .m-md-9-neg {
    margin: -9rem!important
  }
  .mr-md-10-neg,
  .mx-md-10-neg {
    margin-right: -10.5rem!important
  }
  .ml-md-10-neg,
  .mx-md-10-neg {
    margin-left: -10.5rem!important
  }
  .mt-md-10-neg,
  .my-md-10-neg {
    margin-top: -10.5rem!important
  }
  .mb-md-10-neg,
  .my-md-10-neg {
    margin-bottom: -10.5rem!important
  }
  .m-md-10-neg {
    margin: -10.5rem!important
  }
}

@media (min-width:992px) {
  .mr-lg-1-neg,
  .mx-lg-1-neg {
    margin-right: -.25rem!important
  }
  .ml-lg-1-neg,
  .mx-lg-1-neg {
    margin-left: -.25rem!important
  }
  .mt-lg-1-neg,
  .my-lg-1-neg {
    margin-top: -.25rem!important
  }
  .mb-lg-1-neg,
  .my-lg-1-neg {
    margin-bottom: -.25rem!important
  }
  .m-lg-1-neg {
    margin: -.25rem!important
  }
  .mr-lg-2-neg,
  .mx-lg-2-neg {
    margin-right: -.5rem!important
  }
  .ml-lg-2-neg,
  .mx-lg-2-neg {
    margin-left: -.5rem!important
  }
  .mt-lg-2-neg,
  .my-lg-2-neg {
    margin-top: -.5rem!important
  }
  .mb-lg-2-neg,
  .my-lg-2-neg {
    margin-bottom: -.5rem!important
  }
  .m-lg-2-neg {
    margin: -.5rem!important
  }
  .mr-lg-3-neg,
  .mx-lg-3-neg {
    margin-right: -1rem!important
  }
  .ml-lg-3-neg,
  .mx-lg-3-neg {
    margin-left: -1rem!important
  }
  .mt-lg-3-neg,
  .my-lg-3-neg {
    margin-top: -1rem!important
  }
  .mb-lg-3-neg,
  .my-lg-3-neg {
    margin-bottom: -1rem!important
  }
  .m-lg-3-neg {
    margin: -1rem!important
  }
  .mr-lg-4-neg,
  .mx-lg-4-neg {
    margin-right: -1.5rem!important
  }
  .ml-lg-4-neg,
  .mx-lg-4-neg {
    margin-left: -1.5rem!important
  }
  .mt-lg-4-neg,
  .my-lg-4-neg {
    margin-top: -1.5rem!important
  }
  .mb-lg-4-neg,
  .my-lg-4-neg {
    margin-bottom: -1.5rem!important
  }
  .m-lg-4-neg {
    margin: -1.5rem!important
  }
  .mr-lg-5-neg,
  .mx-lg-5-neg {
    margin-right: -3rem!important
  }
  .ml-lg-5-neg,
  .mx-lg-5-neg {
    margin-left: -3rem!important
  }
  .mt-lg-5-neg,
  .my-lg-5-neg {
    margin-top: -3rem!important
  }
  .mb-lg-5-neg,
  .my-lg-5-neg {
    margin-bottom: -3rem!important
  }
  .m-lg-5-neg {
    margin: -3rem!important
  }
  .mr-lg-6-neg,
  .mx-lg-6-neg {
    margin-right: -4.5rem!important
  }
  .ml-lg-6-neg,
  .mx-lg-6-neg {
    margin-left: -4.5rem!important
  }
  .mt-lg-6-neg,
  .my-lg-6-neg {
    margin-top: -4.5rem!important
  }
  .mb-lg-6-neg,
  .my-lg-6-neg {
    margin-bottom: -4.5rem!important
  }
  .m-lg-6-neg {
    margin: -4.5rem!important
  }
  .mr-lg-7-neg,
  .mx-lg-7-neg {
    margin-right: -6rem!important
  }
  .ml-lg-7-neg,
  .mx-lg-7-neg {
    margin-left: -6rem!important
  }
  .mt-lg-7-neg,
  .my-lg-7-neg {
    margin-top: -6rem!important
  }
  .mb-lg-7-neg,
  .my-lg-7-neg {
    margin-bottom: -6rem!important
  }
  .m-lg-7-neg {
    margin: -6rem!important
  }
  .mr-lg-8-neg,
  .mx-lg-8-neg {
    margin-right: -7.5rem!important
  }
  .ml-lg-8-neg,
  .mx-lg-8-neg {
    margin-left: -7.5rem!important
  }
  .mt-lg-8-neg,
  .my-lg-8-neg {
    margin-top: -7.5rem!important
  }
  .mb-lg-8-neg,
  .my-lg-8-neg {
    margin-bottom: -7.5rem!important
  }
  .m-lg-8-neg {
    margin: -7.5rem!important
  }
  .mr-lg-9-neg,
  .mx-lg-9-neg {
    margin-right: -9rem!important
  }
  .ml-lg-9-neg,
  .mx-lg-9-neg {
    margin-left: -9rem!important
  }
  .mt-lg-9-neg,
  .my-lg-9-neg {
    margin-top: -9rem!important
  }
  .mb-lg-9-neg,
  .my-lg-9-neg {
    margin-bottom: -9rem!important
  }
  .m-lg-9-neg {
    margin: -9rem!important
  }
  .mr-lg-10-neg,
  .mx-lg-10-neg {
    margin-right: -10.5rem!important
  }
  .ml-lg-10-neg,
  .mx-lg-10-neg {
    margin-left: -10.5rem!important
  }
  .mt-lg-10-neg,
  .my-lg-10-neg {
    margin-top: -10.5rem!important
  }
  .mb-lg-10-neg,
  .my-lg-10-neg {
    margin-bottom: -10.5rem!important
  }
  .m-lg-10-neg {
    margin: -10.5rem!important
  }
}

@media (min-width:1200px) {
  .mr-xl-1-neg,
  .mx-xl-1-neg {
    margin-right: -.25rem!important
  }
  .ml-xl-1-neg,
  .mx-xl-1-neg {
    margin-left: -.25rem!important
  }
  .mt-xl-1-neg,
  .my-xl-1-neg {
    margin-top: -.25rem!important
  }
  .mb-xl-1-neg,
  .my-xl-1-neg {
    margin-bottom: -.25rem!important
  }
  .m-xl-1-neg {
    margin: -.25rem!important
  }
  .mr-xl-2-neg,
  .mx-xl-2-neg {
    margin-right: -.5rem!important
  }
  .ml-xl-2-neg,
  .mx-xl-2-neg {
    margin-left: -.5rem!important
  }
  .mt-xl-2-neg,
  .my-xl-2-neg {
    margin-top: -.5rem!important
  }
  .mb-xl-2-neg,
  .my-xl-2-neg {
    margin-bottom: -.5rem!important
  }
  .m-xl-2-neg {
    margin: -.5rem!important
  }
  .mr-xl-3-neg,
  .mx-xl-3-neg {
    margin-right: -1rem!important
  }
  .ml-xl-3-neg,
  .mx-xl-3-neg {
    margin-left: -1rem!important
  }
  .mt-xl-3-neg,
  .my-xl-3-neg {
    margin-top: -1rem!important
  }
  .mb-xl-3-neg,
  .my-xl-3-neg {
    margin-bottom: -1rem!important
  }
  .m-xl-3-neg {
    margin: -1rem!important
  }
  .mr-xl-4-neg,
  .mx-xl-4-neg {
    margin-right: -1.5rem!important
  }
  .ml-xl-4-neg,
  .mx-xl-4-neg {
    margin-left: -1.5rem!important
  }
  .mt-xl-4-neg,
  .my-xl-4-neg {
    margin-top: -1.5rem!important
  }
  .mb-xl-4-neg,
  .my-xl-4-neg {
    margin-bottom: -1.5rem!important
  }
  .m-xl-4-neg {
    margin: -1.5rem!important
  }
  .mr-xl-5-neg,
  .mx-xl-5-neg {
    margin-right: -3rem!important
  }
  .ml-xl-5-neg,
  .mx-xl-5-neg {
    margin-left: -3rem!important
  }
  .mt-xl-5-neg,
  .my-xl-5-neg {
    margin-top: -3rem!important
  }
  .mb-xl-5-neg,
  .my-xl-5-neg {
    margin-bottom: -3rem!important
  }
  .m-xl-5-neg {
    margin: -3rem!important
  }
  .mr-xl-6-neg,
  .mx-xl-6-neg {
    margin-right: -4.5rem!important
  }
  .ml-xl-6-neg,
  .mx-xl-6-neg {
    margin-left: -4.5rem!important
  }
  .mt-xl-6-neg,
  .my-xl-6-neg {
    margin-top: -4.5rem!important
  }
  .mb-xl-6-neg,
  .my-xl-6-neg {
    margin-bottom: -4.5rem!important
  }
  .m-xl-6-neg {
    margin: -4.5rem!important
  }
  .mr-xl-7-neg,
  .mx-xl-7-neg {
    margin-right: -6rem!important
  }
  .ml-xl-7-neg,
  .mx-xl-7-neg {
    margin-left: -6rem!important
  }
  .mt-xl-7-neg,
  .my-xl-7-neg {
    margin-top: -6rem!important
  }
  .mb-xl-7-neg,
  .my-xl-7-neg {
    margin-bottom: -6rem!important
  }
  .m-xl-7-neg {
    margin: -6rem!important
  }
  .mr-xl-8-neg,
  .mx-xl-8-neg {
    margin-right: -7.5rem!important
  }
  .ml-xl-8-neg,
  .mx-xl-8-neg {
    margin-left: -7.5rem!important
  }
  .mt-xl-8-neg,
  .my-xl-8-neg {
    margin-top: -7.5rem!important
  }
  .mb-xl-8-neg,
  .my-xl-8-neg {
    margin-bottom: -7.5rem!important
  }
  .m-xl-8-neg {
    margin: -7.5rem!important
  }
  .mr-xl-9-neg,
  .mx-xl-9-neg {
    margin-right: -9rem!important
  }
  .ml-xl-9-neg,
  .mx-xl-9-neg {
    margin-left: -9rem!important
  }
  .mt-xl-9-neg,
  .my-xl-9-neg {
    margin-top: -9rem!important
  }
  .mb-xl-9-neg,
  .my-xl-9-neg {
    margin-bottom: -9rem!important
  }
  .m-xl-9-neg {
    margin: -9rem!important
  }
  .mr-xl-10-neg,
  .mx-xl-10-neg {
    margin-right: -10.5rem!important
  }
  .ml-xl-10-neg,
  .mx-xl-10-neg {
    margin-left: -10.5rem!important
  }
  .mt-xl-10-neg,
  .my-xl-10-neg {
    margin-top: -10.5rem!important
  }
  .mb-xl-10-neg,
  .my-xl-10-neg {
    margin-bottom: -10.5rem!important
  }
  .m-xl-10-neg {
    margin: -10.5rem!important
  }
}

hr.hr-blank {
  border-top: none
}

hr.hr-lg {
  border-top-width: 3px
}

hr.hr-xl {
  border-top-width: 5px
}

hr.hr-primary {
  border-top-color: #55A79A!important
}

hr.hr-success {
  border-top-color: #5cb85c!important
}

hr.hr-info {
  border-top-color: #5bc0de!important
}

hr.hr-warning {
  border-top-color: #f0ad4e!important
}

hr.hr-danger {
  border-top-color: #d9534f!important
}

hr.hr-grey {
  border-top-color: #eee!important
}

hr.hr-faded {
  border-top-color: #f7f7f9!important
}

hr.hr-grey-dark {
  border-top-color: #555!important
}

hr.hr-inverse {
  border-top-color: #292b2c
}

hr.hr-white {
  border-top-color: #fff!important
}

hr.hr-green {
  border-top-color: #55A79A!important
}

hr.hr-red {
  border-top-color: #BE3E1D!important
}

hr.hr-blue {
  border-top-color: #00ADBB!important
}

hr.hr-purple {
  border-top-color: #b771b0!important
}

hr.hr-pink {
  border-top-color: #CC164D!important
}

hr.hr-orange {
  border-top-color: #e67e22!important
}

hr.hr-lime {
  border-top-color: #b1dc44!important
}

hr.hr-blue-dark {
  border-top-color: #34495e!important
}

hr.hr-red-dark {
  border-top-color: #a10f2b!important
}

hr.hr-brown {
  border-top-color: #91633c!important
}

hr.hr-cyan-dark {
  border-top-color: #008b8b!important
}

hr.hr-yellow {
  border-top-color: #D4AC0D!important
}

hr.hr-slate {
  border-top-color: #5D6D7E!important
}

hr.hr-olive {
  border-top-color: olive!important
}

hr.hr-teal {
  border-top-color: teal!important
}

hr.hr-green-bright {
  border-top-color: #2ECC71!important
}

.progress {
  border-radius: 1px
}

.progress-bar {
  padding: 5px;
  height: 1.5rem;
  align-items: center!important;
  -webkit-transition: width 1.2s ease;
  -moz-transition: width 1.2s ease;
  -ms-transition: width 1.2s ease;
  -o-transition: width 1.2s ease;
  transition: width 1.2s ease
}

.progress-bar-sm {
  height: 2px;
  padding: 0
}

.progress-bar-lg {
  height: 2.8rem
}

.progress-bar[data-toggle=progress-bar-animated-progress] {
  width: 0!important
}

.form-control {
  border-radius: 2px
}

.form-control:hover {
  border-color: #E6E6E6
}

label {
  margin-bottom: 2px
}

input[type=color].form-control {
  -webkit-appearance: square-button;
  width: 44px;
  height: 44px;
  background-color: #fff;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(27, 27, 27, .8);
  border-image: initial;
  padding: 1px 2px
}

input[type=range].form-control {
  width: 100%
}

.input-group-btn {
  flex-direction: row
}

.form-control-rounded {
  border-radius: 30px
}

.input-group-quantity .quantity {
  text-align: center
}

.form-control-transparent {
  background: 0 0;
  border-color: transparent;
  color: #555
}

.form-control-transparent:focus,
.form-control-transparent:focus:hover,
.form-control-transparent:hover {
  border-color: transparent;
  background: 0 0;
  color: #000
}

.form-control-transparent::-webkit-input-placeholder {
  color: #555
}

.form-control-transparent:-moz-placeholder {
  color: #555
}

.form-control-transparent::-moz-placeholder {
  color: #555
}

.form-control-transparent:-ms-input-placeholder {
  color: #555
}

.form-control-transparent:focus::-webkit-input-placeholder,
.form-control-transparent:focus:hover::-webkit-input-placeholder,
.form-control-transparent:hover::-webkit-input-placeholder {
  color: #000
}

.form-control-transparent:focus:-moz-placeholder,
.form-control-transparent:focus:hover:-moz-placeholder,
.form-control-transparent:hover:-moz-placeholder {
  color: #000
}

.form-control-transparent:focus::-moz-placeholder,
.form-control-transparent:focus:hover::-moz-placeholder,
.form-control-transparent:hover::-moz-placeholder {
  color: #000
}

.form-control-transparent:focus:-ms-input-placeholder,
.form-control-transparent:focus:hover:-ms-input-placeholder,
.form-control-transparent:hover:-ms-input-placeholder {
  color: #000
}

.form-control-light {
  color: #555
}

.form-control-dark {
  color: rgba(255, 255, 255, .5)
}

.form-control-dark:focus,
.form-control-dark:focus:hover,
.form-control-dark:hover {
  color: #fff
}

.form-control-dark::-webkit-input-placeholder {
  color: rgba(255, 255, 255, .8)
}

.form-control-dark:-moz-placeholder {
  color: rgba(255, 255, 255, .8)
}

.form-control-dark::-moz-placeholder {
  color: rgba(255, 255, 255, .8)
}

.form-control-dark:-ms-input-placeholder {
  color: rgba(255, 255, 255, .8)
}

.form-control-dark:focus::-webkit-input-placeholder,
.form-control-dark:focus:hover::-webkit-input-placeholder,
.form-control-dark:hover::-webkit-input-placeholder {
  color: #fff
}

.form-control-dark:focus:-moz-placeholder,
.form-control-dark:focus:hover:-moz-placeholder,
.form-control-dark:hover:-moz-placeholder {
  color: #fff
}

.form-control-dark:focus::-moz-placeholder,
.form-control-dark:focus:hover::-moz-placeholder,
.form-control-dark:hover::-moz-placeholder {
  color: #fff
}

.form-control-dark:focus:-ms-input-placeholder,
.form-control-dark:focus:hover:-ms-input-placeholder,
.form-control-dark:hover:-ms-input-placeholder {
  color: #fff
}

.input-group-rounded {
  color: red
}

.input-group-rounded .form-control.first-child,
.input-group-rounded .form-control:first-child,
.input-group-rounded .input-group-addon.first-child,
.input-group-rounded .input-group-addon:first-child,
.input-group-rounded .input-group-btn>.btn.first-child,
.input-group-rounded .input-group-btn>.btn:first-child,
.input-group-rounded.input-group .form-control.first-child,
.input-group-rounded.input-group .form-control:first-child {
  border-radius: 30px 0 0 30px
}

.input-group-rounded .form-control.last-child,
.input-group-rounded .form-control:last-child,
.input-group-rounded .input-group-addon.last-child,
.input-group-rounded .input-group-addon:last-child,
.input-group-rounded .input-group-btn>.btn.last-child,
.input-group-rounded .input-group-btn>.btn:last-child,
.input-group-rounded.input-group .form-control.last-child,
.input-group-rounded.input-group .form-control:last-child {
  border-radius: 0 30px 30px 0
}

.input-group-rounded .form-control:not(:first-child):not(:last-child),
.input-group-rounded .input-group-addon:not(:first-child):not(:last-child),
.input-group-rounded .input-group-btn>.btn:not(:first-child):not(:last-child),
.input-group-rounded.input-group .form-control:not(:first-child):not(:last-child) {
  border-radius: 0
}

.form-wrapper {
  margin: 0 auto;
  border: 1px solid #E6E6E6;
  padding: 20px;
  border-radius: 2px;
  box-shadow: 1px 1px 10px rgba(27, 27, 27, .2)
}

.form-wrapper.form-narrow {
  min-width: 200px;
  max-width: 400px
}

.form-wrapper.form-medium {
  min-width: 300px;
  max-width: 500px
}

.form-wrapper h2.title-divider,
.form-wrapper h3.title-divider {
  margin-top: 0
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .02)
}

.table thead th {
  font-weight: 400
}

.popover-title {
  font-size: 16px;
  font-weight: 700
}

.popover-content {
  font-size: 12px;
  font-weight: 400
}

[data-animate] {
  opacity: 0
}

[data-animate].animated,
[data-animate].animated-done {
  opacity: 1
}

.animate-pending {
  opacity: 0
}

.dur-fast {
  -webkit-animation-duration: .5s;
  -moz-animation-duration: .5s;
  -o-animation-duration: .5s;
  animation-duration: .5s
}

.dur-slow {
  -webkit-animation-duration: 2s;
  -moz-animation-duration: 2s;
  -o-animation-duration: 2s;
  animation-duration: 2s
}

.dur-x-slow {
  -webkit-animation-duration: 3s;
  -moz-animation-duration: 3s;
  -o-animation-duration: 3s;
  animation-duration: 3s
}

.de-02 {
  animation-delay: .2s;
  -webkit-animation-delay: .2s
}

.de-04 {
  animation-delay: .4s;
  -webkit-animation-delay: .4s
}

.de-06 {
  animation-delay: .6s;
  -webkit-animation-delay: .6s
}

.de-08 {
  animation-delay: .8s;
  -webkit-animation-delay: .8s
}

.de-1 {
  animation-delay: 1s;
  -webkit-animation-delay: 1s
}

.de-12 {
  animation-delay: 1.2s;
  -webkit-animation-delay: 1.2s
}

.de-14 {
  animation-delay: 1.4s;
  -webkit-animation-delay: 1.4s
}

.de-16 {
  animation-delay: 1.6s;
  -webkit-animation-delay: 1.6s
}

.de-18 {
  animation-delay: 1.8s;
  -webkit-animation-delay: 1.8s
}

.de-2 {
  animation-delay: 2s;
  -webkit-animation-delay: 2s
}

.slider-wrapper {
  width: 100%;
  position: relative;
  padding: 0
}

.slide h1,
.slide h2,
.slide h3,
.slide h4,
.slide h5,
.slide p {
  margin-top: 0;
  font-weight: 400
}

.flex-direction-nav a {
  text-indent: 0;
  font-size: 0;
  border-radius: 0;
  bottom: 0;
  background: 0 0;
  color: transparent;
  width: 50px;
  height: 100px;
  line-height: 100px;
  top: 50%;
  font-weight: 100;
  margin-top: -30px;
  opacity: 0;
  -webkit-transition: all .2s ease-out;
  -moz-transition: all .2s ease-out;
  -ms-transition: all .2s ease-out;
  -o-transition: all .2s ease-out;
  transition: all .2s ease-out
}

.flex-direction-nav a:hover {
  opacity: 1;
  text-decoration: none
}

.flex-direction-nav a:before {
  font-family: FontAwesome;
  font-size: 60px;
  font-weight: 700;
  color: #55A79A
}

.flex-direction-nav a.flex-prev,
.flex-direction-nav a.tp-leftarrow {
  left: 0;
  right: auto
}

.flex-direction-nav a.flex-prev:before,
.flex-direction-nav a.tp-leftarrow:before {
  content: '\f104'
}

.slider-appstrap-theme .slider-wrapper:hover .tparrows,
.slider-wrapper:hover .flex-direction-nav a {
  opacity: 70
}

.slider-appstrap-theme .slider-wrapper:hover .tparrows:hover,
.slider-wrapper:hover .flex-direction-nav a:hover {
  opacity: 100
}

.flex-control-nav,
.slider-appstrap-theme .tp-bullets,
.slider-appstrap-theme .tp-bullets.round,
.slider-appstrap-theme .tp-bullets.simplebullets.round {
  position: absolute;
  bottom: 10px!important;
  right: 10px!important;
  left: auto!important;
  text-align: center;
  width: auto;
  line-height: 1;
  opacity: 100;
  z-index: 300
}

.flex-control-nav li,
.slider-appstrap-theme .tp-bullets li,
.slider-appstrap-theme .tp-bullets.round li,
.slider-appstrap-theme .tp-bullets.simplebullets.round li {
  margin: 0
}

.flex-control-nav .bullet,
.flex-control-nav li a,
.slider-appstrap-theme .tp-bullets .bullet,
.slider-appstrap-theme .tp-bullets li a,
.slider-appstrap-theme .tp-bullets.round .bullet,
.slider-appstrap-theme .tp-bullets.round li a,
.slider-appstrap-theme .tp-bullets.simplebullets.round .bullet,
.slider-appstrap-theme .tp-bullets.simplebullets.round li a {
  margin: 0 3px;
  border-radius: 2px;
  height: 15px;
  width: 15px;
  background: #bbb
}

.flex-control-nav .bullet.flex-active,
.flex-control-nav .bullet.selected,
.flex-control-nav .bullet:hover,
.flex-control-nav li a.flex-active,
.flex-control-nav li a.selected,
.flex-control-nav li a:hover,
.slider-appstrap-theme .tp-bullets .bullet.flex-active,
.slider-appstrap-theme .tp-bullets .bullet.selected,
.slider-appstrap-theme .tp-bullets .bullet:hover,
.slider-appstrap-theme .tp-bullets li a.flex-active,
.slider-appstrap-theme .tp-bullets li a.selected,
.slider-appstrap-theme .tp-bullets li a:hover,
.slider-appstrap-theme .tp-bullets.round .bullet.flex-active,
.slider-appstrap-theme .tp-bullets.round .bullet.selected,
.slider-appstrap-theme .tp-bullets.round .bullet:hover,
.slider-appstrap-theme .tp-bullets.round li a.flex-active,
.slider-appstrap-theme .tp-bullets.round li a.selected,
.slider-appstrap-theme .tp-bullets.round li a:hover,
.slider-appstrap-theme .tp-bullets.simplebullets.round .bullet.flex-active,
.slider-appstrap-theme .tp-bullets.simplebullets.round .bullet.selected,
.slider-appstrap-theme .tp-bullets.simplebullets.round .bullet:hover,
.slider-appstrap-theme .tp-bullets.simplebullets.round li a.flex-active,
.slider-appstrap-theme .tp-bullets.simplebullets.round li a.selected,
.slider-appstrap-theme .tp-bullets.simplebullets.round li a:hover {
  background: #55A79A
}

.slider-mini-nav .flex-control-nav,
.slider-mini-nav .slider-appstrap-theme .tp-bullets,
.slider-mini-nav .slider-appstrap-theme .tp-bullets.round,
.slider-mini-nav .slider-appstrap-theme .tp-bullets.simplebullets.round {
  bottom: 5px!important;
  right: 5px!important
}

.slider-mini-nav .flex-control-nav .bullet,
.slider-mini-nav .flex-control-nav li a,
.slider-mini-nav .slider-appstrap-theme .tp-bullets .bullet,
.slider-mini-nav .slider-appstrap-theme .tp-bullets li a,
.slider-mini-nav .slider-appstrap-theme .tp-bullets.round .bullet,
.slider-mini-nav .slider-appstrap-theme .tp-bullets.round li a,
.slider-mini-nav .slider-appstrap-theme .tp-bullets.simplebullets.round .bullet,
.slider-mini-nav .slider-appstrap-theme .tp-bullets.simplebullets.round li a {
  margin: 0 2px;
  height: 12px;
  width: 12px
}

.slider-boxed #highlighted,
.slider-full-width #highlighted,
.slider-full-width-behind #highlighted {
  background: 0 0
}

.slider-boxed #highlighted {
  border: none
}

.slider-boxed .navbar {
  -webkit-border-bottom-right-radius: 0!important;
  -webkit-border-bottom-left-radius: 0!important;
  -moz-border-radius-bottomright: 0!important;
  -moz-border-radius-bottomleft: 0!important;
  border-bottom-right-radius: 0!important;
  border-bottom-left-radius: 0!important
}

.slider-boxed .flexslider-wrapper,
.slider-boxed .slider-wrapper {
  border: 1px solid rgba(27, 27, 27, .15);
  border-radius: 0;
  margin-top: 10px;
  box-shadow: 1px 1px 10px rgba(27, 27, 27, .2)
}

.slider-boxed .slide-content {
  padding-top: 20px
}

.slider-full-width .slide-content {
  padding: 2em 0 0
}

.has-backstretch .backstretch-overlay {
  background: 0 0;
  background-color: rgba(255, 255, 255, .7)!important;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: -999998
}

.overlay,
.overlay-open {
  position: relative
}

.has-backstretch .backstretch-overlay else {
  background-color: rgba(255, 255, 255, .7)
}

.w-5 {
  width: 5%!important
}

.w-10 {
  width: 10%!important
}



.w-50 {
  width: 50%!important
}



.w-100 {
  width: 100%!important
}


.overlay.overlay-fp,
.overlay:after {
  top: 0;
  height: 100%;
  right: 0;
  width: 100%;
  bottom: 0;
  left: 0
}



.overlay:after {
  content: '';
  position: absolute;
  opacity: .95;
  display: block!important;
  background: #242424
}

.overlay>* {
  z-index: 4;
  position: relative
}



.flex-valign,
.overlay-hover .overlay-hover-content {
  -webkit-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal
}


.overlay-hover-content.overlay-blue,
.overlay-hover-content.overlay-blue .overlay-close,
.overlay-hover-content.overlay-blue a:not(.btn),
.overlay-hover-content.overlay-blue-dark,
.overlay-hover-content.overlay-blue-dark .overlay-close,
.overlay-hover-content.overlay-blue-dark a:not(.btn),
.overlay-hover-content.overlay-brown,
.overlay-hover-content.overlay-brown .overlay-close,
.overlay-hover-content.overlay-brown a:not(.btn),
.overlay-hover-content.overlay-cyan-dark,
.overlay-hover-content.overlay-cyan-dark .overlay-close,
.overlay-hover-content.overlay-cyan-dark a:not(.btn),
.overlay-hover-content.overlay-danger,
.overlay-hover-content.overlay-danger .overlay-close,
.overlay-hover-content.overlay-danger a:not(.btn),
.overlay-hover-content.overlay-green,
.overlay-hover-content.overlay-green .overlay-close,
.overlay-hover-content.overlay-green a:not(.btn),
.overlay-hover-content.overlay-green-bright,
.overlay-hover-content.overlay-green-bright .overlay-close,
.overlay-hover-content.overlay-green-bright a:not(.btn),
.overlay-hover-content.overlay-info,
.overlay-hover-content.overlay-info .overlay-close,
.overlay-hover-content.overlay-info a:not(.btn),
.overlay-hover-content.overlay-lime,
.overlay-hover-content.overlay-lime .overlay-close,
.overlay-hover-content.overlay-lime a:not(.btn),
.overlay-hover-content.overlay-olive,
.overlay-hover-content.overlay-olive .overlay-close,
.overlay-hover-content.overlay-olive a:not(.btn),
.overlay-hover-content.overlay-orange,
.overlay-hover-content.overlay-pink,
.overlay-hover-content.overlay-pink .overlay-close,
.overlay-hover-content.overlay-pink a:not(.btn),
.overlay-hover-content.overlay-primary,
.overlay-hover-content.overlay-primary .overlay-close,
.overlay-hover-content.overlay-primary a:not(.btn),
.overlay-hover-content.overlay-purple,
.overlay-hover-content.overlay-purple .overlay-close,
.overlay-hover-content.overlay-purple a:not(.btn),
.overlay-hover-content.overlay-red,
.overlay-hover-content.overlay-red .overlay-close,
.overlay-hover-content.overlay-red a:not(.btn),
.overlay-hover-content.overlay-red-dark,
.overlay-hover-content.overlay-red-dark .overlay-close,
.overlay-hover-content.overlay-red-dark a:not(.btn),
.overlay-hover-content.overlay-slate,
.overlay-hover-content.overlay-slate .overlay-close,
.overlay-hover-content.overlay-slate a:not(.btn),
.overlay-hover-content.overlay-success,
.overlay-hover-content.overlay-success .overlay-close,
.overlay-hover-content.overlay-success a:not(.btn),
.overlay-hover-content.overlay-teal,
.overlay-hover-content.overlay-teal .overlay-close,
.overlay-hover-content.overlay-teal a:not(.btn),
.overlay-hover-content.overlay-warning,
.overlay-hover-content.overlay-warning .overlay-close,
.overlay-hover-content.overlay-warning a:not(.btn),
.overlay-hover-content.overlay-yellow,
.overlay-hover-content.overlay-yellow .overlay-close,
.overlay-hover-content.overlay-yellow a:not(.btn),
.overlay.overlay-blue,
.overlay.overlay-blue .overlay-close,
.overlay.overlay-blue a:not(.btn),
.overlay.overlay-blue-dark,
.overlay.overlay-blue-dark .overlay-close,
.overlay.overlay-blue-dark a:not(.btn),
.overlay.overlay-brown,
.overlay.overlay-brown .overlay-close,
.overlay.overlay-brown a:not(.btn),
.overlay.overlay-cyan-dark,
.overlay.overlay-cyan-dark .overlay-close,
.overlay.overlay-cyan-dark a:not(.btn),
.overlay.overlay-danger,
.overlay.overlay-danger .overlay-close,
.overlay.overlay-danger a:not(.btn),
.overlay.overlay-green,
.overlay.overlay-green .overlay-close,
.overlay.overlay-green a:not(.btn),
.overlay.overlay-green-bright,
.overlay.overlay-green-bright .overlay-close,
.overlay.overlay-green-bright a:not(.btn),
.overlay.overlay-info,
.overlay.overlay-info .overlay-close,
.overlay.overlay-info a:not(.btn),
.overlay.overlay-lime,
.overlay.overlay-lime .overlay-close,
.overlay.overlay-lime a:not(.btn),
.overlay.overlay-olive,
.overlay.overlay-olive .overlay-close,
.overlay.overlay-olive a:not(.btn),
.overlay.overlay-orange,
.overlay.overlay-pink,
.overlay.overlay-pink .overlay-close,
.overlay.overlay-pink a:not(.btn),
.overlay.overlay-primary,
.overlay.overlay-primary .overlay-close,
.overlay.overlay-primary a:not(.btn),
.overlay.overlay-purple,
.overlay.overlay-purple .overlay-close,
.overlay.overlay-purple a:not(.btn),
.overlay.overlay-red,
.overlay.overlay-red .overlay-close,
.overlay.overlay-red a:not(.btn),
.overlay.overlay-red-dark,
.overlay.overlay-red-dark .overlay-close,
.overlay.overlay-red-dark a:not(.btn),
.overlay.overlay-slate,
.overlay.overlay-slate .overlay-close,
.overlay.overlay-slate a:not(.btn),
.overlay.overlay-success,
.overlay.overlay-success .overlay-close,
.overlay.overlay-success a:not(.btn),
.overlay.overlay-teal,
.overlay.overlay-teal .overlay-close,
.overlay.overlay-teal a:not(.btn),
.overlay.overlay-warning,
.overlay.overlay-warning .overlay-close,
.overlay.overlay-warning a:not(.btn),
.overlay.overlay-yellow,
.overlay.overlay-yellow .overlay-close,
.overlay.overlay-yellow a:not(.btn),
.timeline-breaker,
.timeline-breaker a {
  color: #fff
}


.overlay-hover-content.overlay-primary:after,
.overlay.overlay-primary:after {
  background: #55A79A
}


.overlay-hover-content.overlay-op-7:after,
.overlay.overlay-op-7:after {
  opacity: .7
}


.fa {
  vertical-align: middle
}


.bg-img,
.bg-img-cover {
  background-size: cover
}


.flex-valign {
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center
}


.header-compact .header,
.header-compact-sticky .header.is-sticky,
.header-compact-sticky .is-sticky .header {
  padding: 7.5px 0
}


.text-grey {
  color: #eee!important
}

.bg-inverse {
  background-color: #292b2c!important
}


.bg-white {
  background-color: #fff!important
}

.bg-faded {
  background-color: #f7f7f7!important
}

.bg-grey {
  background-color: #eee!important
}

.bg-shadow {
  box-shadow: 1px 1px 5px #d2d2d2
}


.text-shadow {
  text-shadow: 1px 1px 1px rgba(231, 231, 231, .8)
}


.font-weight-bold {
  font-weight: 700!important
}

.font-weight-normal {
  font-weight: 400!important
}

.font-weight-light {
  font-weight: 100!important
}


.divider-inverse.divider-arrow-l:before,
.divider-inverse.divider-diagonal-r:before {
  border-right-color: #292b2c
}

.divider-inverse.divider-arrow-r:before,
.divider-inverse.divider-diagonal:before {
  border-left-color: #292b2c
}

.divider-diagonal {
  position: relative;
  overflow: hidden
}

.divider-diagonal:before {
  border-right-color: transparent;
  border-left-width: 150px;
  border-left-style: solid;
  border-top: 750px solid transparent;
  height: 750px;
  margin-bottom: -750px;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  content: '';
  z-index: 1
}

.divider-diagonal.divider-diagonal-r:before {
  border-left-color: transparent;
  border-right-width: 150px;
  border-right-style: solid;
  border-top: 750px solid transparent;
  right: 0;
  left: auto
}
