.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pgb .step {
    text-align: center;
    position:relative;
    }
.pgb h2 {
    font-size:1.3rem;
}
.pgb .step p {
    position:absolute;
    height:60px;
    width:100%;
    text-align:center;
    display:block;
    z-index:3;
    color:#fff;
    font-size:160%;
    line-height:55px;
    opacity:.7;
}
.pgb .active.step p {
    opacity:1;
    font-weight:600;
}
.pgb .img-circle {
      display: inline-block;
      width: 60px;
      height: 60px;
      border-radius:50%;
      background-color:#9E9E9E;
    border:4px solid #fff;
    }
.pgb .complete .img-circle {
      background-color:#4CAF50;
    }
.pgb .active .img-circle {
      background-color:#FF9800;
    }
.pgb .step .img-circle:before {
        content: "";
        display: block;
        background: #9E9E9E;
        height: 4px;
        width: 50%;
        position: absolute;
        bottom: 50%;
        left: 0;
        z-index: -1;
        margin-right:24px;
    }
.pgb .step .img-circle:after {
        content: "";
        display: block;
        background: #9E9E9E;
        height: 4px;
        width: 50%;
        position: absolute;
        bottom: 50%;
        left: 50%;
        z-index: -1;
    }
.pgb .step.active .img-circle:after {
        background: #9E9E9E;
    }

.pgb .step.complete .img-circle:after, .pgb .step.active .img-circle:before {
        background: #4CAF50;
    }

    .pgb .step:last-of-type .img-circle:after, .pgb .step:first-of-type .img-circle:before{
        display: none;
    }

    .stepwizard-step p {
    margin-top: 0px;
    color:#666;
}
.stepwizard-row {
    display: table-row;
}
.stepwizard {
    display: table;
    width: 100%;
    position: relative;
}
.stepwizard-step button[disabled] {
    /*opacity: 1 !important;
    filter: alpha(opacity=100) !important;*/
}
.stepwizard .btn.disabled, .stepwizard .btn[disabled], .stepwizard fieldset[disabled] .btn {
    opacity:1 !important;
    color:#bbb;
}
.stepwizard-row:before {
    top: 14px;
    bottom: 0;
    position: absolute;
    content:" ";
    width: 100%;
    height: 1px;
    background-color: #ccc;
    z-index: 0;
}
.stepwizard-step {
    display: table-cell;
    text-align: center;
    position: relative;
}
.btn-circle {
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 6px 0;
    font-size: 12px;
    line-height: 1.428571429;
    border-radius: 15px;
}

.countdown {
    text-transform: uppercase;
    font-weight: bold;
}

.countdown span {
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    font-size: 3rem;
    margin-left: 0.8rem;
}

.countdown span:first-of-type {
    margin-left: 0;
}

.countdown-circles {
    text-transform: uppercase;
    font-weight: bold;
}

.countdown-circles span {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.countdown-circles span:first-of-type {
    margin-left: 0;
}

.input-group-prepend {
  width : 70%; /*adjust as needed*/
}

.input-group-prepend label {
  width: 100%;
  overflow: hidden;
}

